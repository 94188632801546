







import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { reportTypeStores } from '@/store/poultry/reportType';

@Component({
  components: {
  }
})
export default class AnalysisEmpty extends Vue {
  reportTypeDetailStore = reportTypeStores.detail;
  
  get isPhaseThreeReport() {
    switch (this.reportTypeDetailStore.reportTypeId) {
      case 1:
      case 2:
      case 3:
        return false;
      default:
        return true;
    }
  }
}
