






















































import { Component, Vue, Prop, Watch, Emit } from 'vue-property-decorator';
import { DropdownOption } from '@/models/poultry/dropdown.model';
import { reportTypeStores} from '@/store/poultry/reportType';
import { ReportTypeIds } from '@/constants';

@Component({})
export default class DropdownFilterAlt extends Vue {
  @Prop() disabled!: boolean;
  @Prop() filterType!: string;
  @Prop() isResetSelected!: boolean;
  @Prop() resetTitle!: string;
  @Prop() isAnalysis!: boolean;
  @Prop() defaultPlaceholder!: string;

  @Prop({
    type: Array,
    default: [],
    required: true,
  })
  private options!: DropdownOption[];

  @Prop()
  private selectedOption!: DropdownOption | string;

  isActive = false;
  selected: DropdownOption = {
    text: '',
    value: '',
  };
  keywords: string = '';
  reportTypeDetailStore = reportTypeStores.detail;

  @Emit('select')
  handleSelected(data: DropdownOption) {
    this.selected = data;
    this.isActive = false;
    return data;
  }

  @Emit('select')
  handleResetSelected() {
    this.selected = {
      text: '',
      value: '',
    };
    return this.selected;
  }

  created() {
    this.initializeDefaultOption();
  }

  mounted() {
    document.addEventListener('click', this.closeDropdown);
  }

  destroyed() {
    document.removeEventListener('click', this.closeDropdown);
  }

  closeDropdown(e: any) {
    if (!this.$el.contains(e.target)) {
      this.isActive = false;
    }
  }

  getReportStyleByReportTypeId(reportTypeId: string): string{
    let style = '';

    switch(reportTypeId){
      case ReportTypeIds.water:
        style = 'water';
        break;
      case ReportTypeIds.injectable:
        style = 'injectable';
        break;
      case ReportTypeIds.hatchery:
        style = 'hatchery';
        break;
      case ReportTypeIds.cnd:
        style = 'cleaning';
        break;
      case ReportTypeIds.ipm:
        style = 'ipm';
        break;
      case ReportTypeIds.labOnWheel:
        style = 'low';
        break;
      case ReportTypeIds.eba:
        style = 'eba';
        break;
      case ReportTypeIds.pea:
        style = 'pea';
        break;
      default:
        style = '';
        break;
    }
    return style;
  }

  changeBorder(){
    return this.getReportStyleByReportTypeId(String(this.reportTypeDetailStore.reportTypeId));
  }

  changeDropdownColor(){
    let dropdownColor = this.getReportStyleByReportTypeId(String(this.reportTypeDetailStore.reportTypeId));
    return `is-active-${dropdownColor}`;
  }

  @Emit('onSearch')
  handleFormSubmitted() {
    return this.keywords;
  }

  initializeDefaultOption() {
    if (this.selectedOption && this.selectedOption.constructor === Object) {
      this.selected = this.selectedOption as DropdownOption;
    } else {
      const option = this.options.find((el) => {
        el.value === this.selectedOption;
      });

      if (option) {
        this.selected = option;
      } else {
        this.selected = {
          text: 'Client',
          value: '',
        };
      }

      if (this.defaultPlaceholder){
        this.selected = {
          text: this.defaultPlaceholder,
          value: ''
        };
      }
    }
  }
}
