import { resultsAnalysis } from './results';
import { preparationAnalysis } from './preparation';
import { vaccinationAnalysis} from './vaccination';
import { hatcheryAnalysis } from './hatchery';
import { visualAnalysis } from './visual';
import { swabSampleAnalysis } from './swab_sample';

export const analysisStores = {
  results: resultsAnalysis,
  preparation: preparationAnalysis,
  vaccination: vaccinationAnalysis,
  hatchery: hatcheryAnalysis,
  visual: visualAnalysis,
  swab_sample: swabSampleAnalysis,
};
