import { ErrorResponse } from '@/models/poultry/response';
import { axios } from '@/utils/axios';
import { Action, getModule, Module, Mutation, MutationAction, VuexModule } from 'vuex-module-decorators';
import { Auditor, AuditorState, AuditorResponse } from '@/models/poultry/auditor.model';
import { Endpoints } from '../endpoints';
import store from '../index';
import { ToastProgrammatic as Toast } from 'buefy';
import { clientStores } from '@/store/poultry/client/index';
export interface AuditorDetailsStoreInterface {
}
@Module({
  namespaced: true,
  dynamic: true,
  name: 'auditor.details',
  store
})
class AuditorDetailsStore extends VuexModule {
  isLoading = false;
  isUpdated = false;
  isDeleted = false;
  error: ErrorResponse | null = null;
  auditors: Auditor | null = null;
  auditorsDropdown: AuditorResponse[] = [];
  auditorId: number | null = null;
  auditor: AuditorState = {
    fullName: '',
    phoneNumber: '',
    email: '',
    password: '',
    reportTypeIds: [],
  };

  @Mutation
  updateAuditor(params: AuditorResponse) {
    this.auditor.fullName = params.userProfile.name;
    this.auditor.phoneNumber = params.userProfile.phoneNumber;
    this.auditor.email = params.email;
    this.auditor.password = (params as any).password ;
    if (params.client) {
      this.auditor.client = params.client
    }
    if (params.reportTypes) {
      this.auditor.reportTypeIds = params.reportTypes.reportTypeIds;
    }
  }

  @Mutation
  updateAuditorsDropdown(params: AuditorResponse[]) {
    this.auditorsDropdown = params;
  }

  @Mutation
  setAuditorId(params: number) {
    this.auditorId = params;
  }

  @Mutation
  setError(params: ErrorResponse) {
    this.error = params;
  }

  @Mutation
  updateLoadingStatus(params: boolean) {
    this.isLoading = params;
  }

  @Mutation
  setUpdateFlag(params: boolean) {
    this.isUpdated = params;
  }

  @Mutation
  setDeleteFlag(params: boolean) {
    this.isDeleted = params;
  }

  @Action
  async fetchById() {
    this.context.commit('updateLoadingStatus', true);
    try {
      const { data } = await axios.get(`${Endpoints.Clients}/${clientStores.details.clientId}/auditors/${this.auditorId}`);
      this.context.commit('updateAuditor', data);
    } catch(error) {
      this.context.commit('setError', error);
    }
    this.context.commit('updateLoadingStatus', false);
  }

  @Action
  async fetchAuditorsDropdown(clientId: string | number) {
    this.context.commit('updateLoadingStatus', true);
    try {
      const { data } = await axios.get(`${Endpoints.Clients}/${clientId}/auditors/dropdown`);
      this.context.commit('updateAuditorsDropdown', data);
    } catch (error) {
      this.context.commit('setError', error);
    }
    this.context.commit('updateLoadingStatus', false);
  }

  @Action
  async update() {
    this.context.commit('updateLoadingStatus', true);
    try {
      let bodyParams = {...this.auditor} as any;

      if (!this.auditor.password) {
        const { password, ...redacted } = bodyParams;
        bodyParams = redacted;
      }

      const { data } = await axios.put(`${Endpoints.Clients}/${clientStores.details.clientId}/auditors/${this.auditorId}`, bodyParams);
      Toast.open({
        type: 'is-success',
        message: 'Auditor successfully updated',
      });

      this.context.commit('setUpdateFlag', true);
      this.context.commit('updateAuditor', data);
    } catch (error) {
      this.context.commit('setError', error);
    }
    this.context.commit('updateLoadingStatus', false);
  }

  @Action
  async delete() {
    this.context.commit('setDeleteFlag', false);
    this.context.commit('updateLoadingStatus', true);
    try {
      await axios.delete(`${Endpoints.Clients}/${clientStores.details.clientId}/auditors/${this.auditorId}`);
      this.context.commit('setDeleteFlag', true);
    } catch(error) {
      this.context.commit('setError', error);
    }
    this.context.commit('updateLoadingStatus', false);
  }
 }

export default getModule(AuditorDetailsStore);
