import { ErrorResponse } from '@/models/poultry/response';
import { axios } from '@/utils/axios';
import { Action, getModule, Module, Mutation, MutationAction, VuexModule } from 'vuex-module-decorators';
import { DropdownOption } from '@/models/poultry/dropdown.model';
import { Endpoints } from '@/store/poultry/endpoints';
import store from '@/store/poultry/index';
import { AnalysisData, ScoreAnalysis } from '@/models/poultry/analysis.model';
import arraySort from 'array-sort';
@Module({
    namespaced: true,
    dynamic: true,
    name: 'hatchery.list',
    store
})
class HatcheryAnalysisListStore extends VuexModule {
    isLoading = false;
    isCreated = false;
    hatchery = [];
    error: ErrorResponse | null = null;
    title: string | null = null;
    analysisType: string | null = null;
    reportTypeId: number | null = null;
    stateIds: string[] = [];
    complexIds: string[] = [];
    auditorIds: string[] = [];
    clientIds: string[] = [];
    clientName: string | null = null;
    startDate: string | null = null;
    endDate: string | null = null;
    categoryType: string | null = null;
    hatcheryTypeText: string | null = null;
    hatcheryTypeValue: string | null = null;
    currentSort: keyof AnalysisData = 'name';
    currentSortOrder = { reverse: true }; // false is asc, true is desc

    get hasHatcheries(): boolean {
        return this.isLoading === false && Boolean(this.hatchery.length);
    }

    get sortedHatcheries() {
        const hatcheryCopy: AnalysisData[] = [...this.hatchery];
        const finalHatchery: AnalysisData[] = [];

        hatcheryCopy.forEach(items => {
        let data = items.data.reduce((data: any, currentData: ScoreAnalysis) => {
            // if highest not empty or if current score greater, in highest
            if (!data.highestReport || currentData.score > data.highestReport.score) {
                data = {...data, highestReport : currentData};
            }
            // if have same highest score append month together
            else if (currentData.score === data.highestReport.score) {
            data = {
                    ...data,
                    highestReport: {
                        ...data.highestReport,
                        publishedMonth: data.highestReport.publishedMonth+ ', '+currentData.publishedMonth
                    }
                }
            }

            // if lowest not empty or if current score lower, in lowest
            if (!data.lowestReport || currentData.score < data.lowestReport.score) {
                data = {...data, lowestReport : currentData};
            }
            // if have same lowest score append month together
            else if (currentData.score === data.lowestReport.score) {
                data = {
                    ...data,
                    lowestReport: {
                        ...data.lowestReport,
                        publishedMonth: data.lowestReport.publishedMonth+ ', '+currentData.publishedMonth
                    }
                }
            }
            return data;
        }, {});
        // For Others row, that got score 0
        data = {
            highestReport : {
                score: 0,
                publishedMonth:'-'
            },
            lowestReport: {
                score: 0,
                publishedMonth:'-'
            },
            ...data,
        }
        // Append data object in items
        items = {
            ...items,
            ...data
        }
            // Push items to array
            finalHatchery.push(items);
        });
        return arraySort(finalHatchery, this.currentSort, this.currentSortOrder);
    }

    @Mutation
    setSortType(params: any) {
        if (params === this.currentSort) {
        this.currentSortOrder.reverse = !this.currentSortOrder.reverse;
        }
        this.currentSort = params;
    }

    @Mutation
    updateLoadingStatus(params: boolean) {
        this.isLoading = params;
    }

    @Mutation
    updateHatchery(params: any) {
        this.hatchery = params;
    }

    @Mutation
    setAnalysisType(params: string) {
        this.analysisType = params;
    }

    @Mutation
    setStartDate(params: string) {
        this.startDate = params;
    }

    @Mutation
    setEndDate(params: string) {
        this.endDate = params;
    }

    @Mutation
    setClientIds(params: string[]) {
        this.clientIds = params;
    }

    @Mutation
    setClientName(params: DropdownOption) {
        this.clientName = params.text;
    }

    @Mutation
    setCategoryType(params: string) {
        this.categoryType = params;
    }

    @Mutation
    setSectionType(params: DropdownOption) {
        this.hatcheryTypeText = params.text;
        this.hatcheryTypeValue = params.value;
    }

    @Mutation
    setStates(params: string[]) {
        this.stateIds = params;
    }

    @Mutation
    setComplexes(params: string[]) {
        this.complexIds = params;
    }

    @Mutation
    setAuditors(params: string[]) {
        this.auditorIds = params;
    }

    @Mutation
    setError(params: ErrorResponse) {
        this.error = params;
    }

    @Mutation
    setHatcheryCreated(params: boolean) {
        this.isCreated = params;
    }

    @Mutation
    setReportTypeId(params: any) {
        this.reportTypeId = Number(params)
    }

    // TODO: Refactor
    @Action
    async fetchAll() {
        this.context.commit('updateLoadingStatus', true);
        this.context.commit('setHatcheryCreated', false);
        try {
            if (this.analysisType === 'between_clients') {
                const { data } = await axios.get(Endpoints.HatcheryAnalysis, {
                    params: {
                        analysis_type: this.analysisType,
                        section: this.hatcheryTypeValue,
                        client_ids: this.clientIds.toString(),
                        report_type_id: this.reportTypeId,
                        start_date: this.startDate,
                        end_date: this.endDate,
                    }
                });
                this.context.commit('updateHatchery', data);
                this.context.commit('setHatcheryCreated', true);
            }

            if (this.analysisType === 'between_complexes') {
                if (this.categoryType === 'state') {
                    const { data } = await axios.get(Endpoints.HatcheryAnalysis, {
                        params: {
                            analysis_type: this.analysisType,
                            section: this.hatcheryTypeValue,
                            client_id: this.clientIds.toString(),
                            report_type_id: this.reportTypeId,
                            start_date: this.startDate,
                            end_date: this.endDate,
                            state_ids: this.stateIds.toString(),
                        }
                    });
                    this.context.commit('updateHatchery', data);
                    this.context.commit('setHatcheryCreated', true);
                }
        
                if (this.categoryType === 'auditor') {
                    const { data } = await axios.get(Endpoints.HatcheryAnalysis, {
                        params: {
                            analysis_type: this.analysisType,
                            section: this.hatcheryTypeValue,
                            client_id: this.clientIds.toString(),
                            report_type_id: this.reportTypeId,
                            start_date: this.startDate,
                            end_date: this.endDate,
                            auditor_ids: this.auditorIds.toString(),
                        }
                    });
                    this.context.commit('updateHatchery', data);
                    this.context.commit('setHatcheryCreated', true); 
                }
            }

        } catch(error) {
            this.context.commit('setError', error);
        }
        this.context.commit('updateLoadingStatus', false);
    }
}

export default getModule(HatcheryAnalysisListStore);
