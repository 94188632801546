















































































import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { analysisStores } from '@/store/poultry/analysis';
import SortIndicator from '@/components/poultry/SortIndicator.vue';

@Component({
  components: {
    SortIndicator
  }
})
export default class VaccinationAnalysisTableHeader extends Vue {
  store = analysisStores.vaccination.list;

  get categoryType() {
    return this.store.categoryType;
  }

  get analysisType() {
    return this.store.analysisType;
  }

  sort(sortType: string) {
    this.store.setSortType(sortType);
  }

  get currentSort() {
    return this.store.currentSort;
  }

  get currentSortOrder() {
    return this.store.currentSortOrder.reverse;
  }
}
