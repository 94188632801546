






import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import VaccinationAnalysisChartHeader from './VaccinationAnalysisChartHeader.vue';
import VaccinationAnalysisChartBody from './VaccinationAnalysisChartBody.vue';

@Component({
  components: {
    VaccinationAnalysisChartHeader,
    VaccinationAnalysisChartBody
  }
})
export default class VaccinationAnalysisChart extends Vue {
}
