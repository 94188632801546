import dayjs from 'dayjs';

export function setFormattedDate(date: string) {
  let formattedDate: any = [];
  // Others date doesnt have value, it has - value
  if (date === '-') {
    return date
  }
  formattedDate = date.split(', ').map((currentDate: string) => {
    return dayjs(currentDate).format('MMM YYYY');
  });
  return formattedDate.join(', ');
}