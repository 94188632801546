import { PaginationModel } from '@/models/poultry/pagination.model';
import { ReportResponse } from '@/models/poultry/report.model';
import { SearchPaginatedRequest } from '@/models/poultry/requests';
import { ErrorResponse, PaginatedResponse } from '@/models/poultry/response';
import { axios } from '@/utils/axios';
import arraySort from 'array-sort';
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { DEFAULT_COUNT_PER_PAGE } from '@/constants';
import { Endpoints } from '../endpoints';
import store from '../index';

export interface ReportFetchAllParams extends Partial<PaginationModel> {
  clientId: number | string,
  farmIds: string[],
  auditorIds: string[],
  reportTypeIds: string[],
  months: string[],
  years: string[]
}

@Module({
  namespaced: true,
  dynamic: true,
  name: 'report.list',
  store,
})
class ListReportStore extends VuexModule {
  isLoading = false;
  error: ErrorResponse | null = null;
  reports: ReportResponse[] = [];
  pagination = { ...this.defaultPagination };
  paginateBy = 'normalListing';

  apiParams: any = {
    clientId: null,
    farmIds: [],
    complexIds: [],
    auditorIds: [],
    months: [],
    years: [],
  }

  currentSort: keyof ReportResponse = 'dateOfAudit';
  currentSortOrder = { reverse: true }; // false is asc, true is desc

  get defaultPagination(): PaginationModel {
    return {
      offset: 1,
      limit: DEFAULT_COUNT_PER_PAGE,
    };
  }

  get hasReports(): boolean {
    return this.isLoading === false && Boolean(this.reports.length);
  }

  get sortedReports() {
    const reportsCopy = [...this.reports];

    return arraySort(reportsCopy, this.currentSort, this.currentSortOrder);
  }

  @Mutation
  setSortType(params: any) {
    if (params === this.currentSort) {
      this.currentSortOrder.reverse = !this.currentSortOrder.reverse;
    }
    this.currentSort = params;
  }

  @Mutation
  updateReports(reports: ReportResponse[]) {
    this.reports = reports;
  }

  @Mutation
  updatePagination(pagination: PaginationModel) {
    this.pagination = pagination;
  }

  @Mutation
  updateApiParams(apiParams: any) {
    this.apiParams = apiParams;
  }

  @Mutation
  setError(params: ErrorResponse) {
    this.error = params;
  }

  @Mutation
  updateLoadingStatus(params: boolean) {
    this.isLoading = params;
  }

  @Mutation
  updatePaginateBy(params: string) {
    this.paginateBy = params;
  }

  @Action
  async fetchAll(
    params: ReportFetchAllParams,
  ): Promise<void> {
    const { clientId, farmIds, auditorIds, reportTypeIds, months, years } = params;
    
    this.context.commit('updateApiParams', {
      clientId: clientId || null,
      farmIds: farmIds || [],
      auditorIds: auditorIds || [],
      reportTypeIds: reportTypeIds || [],
      months: months || [],
      years: years || []
    })

    const apiParams: any = {};

    if (this.apiParams.clientId) {
      apiParams['client_ids'] = this.apiParams.clientId;
    }

    if (this.apiParams.reportTypeIds.length) {
      apiParams['report_type_ids'] = this.apiParams.reportTypeIds.toString();
    }

    if (this.apiParams.farmIds.length) {
      apiParams['farm_ids'] = this.apiParams.farmIds.toString();
    }

    if (this.apiParams.auditorIds.length) {
      apiParams['auditor_ids'] = this.apiParams.auditorIds.toString();
    }

    if (this.apiParams.months.length) {
      apiParams['months'] = this.apiParams.months.toString();
    }

    if (this.apiParams.years.length) {
      apiParams['years'] = this.apiParams.years.toString();
    }

    apiParams['offset'] = this.pagination.offset;
    apiParams['limit'] = this.pagination.limit;

    this.context.commit('updateLoadingStatus', true);
    try {
      const { data } = await axios.get(`${Endpoints.Reports}/poultry`, {
        params: apiParams
      });
      
      this.context.commit('updateReports', data.items);
      this.context.commit('updatePagination', data.pagination);
      this.context.commit('updatePaginateBy', 'normalListing');
    } catch (error) {
      this.context.commit('setError', error);
    }
    this.context.commit('updateLoadingStatus', false);
  }

  @Action
  async searchByKeywords(params: SearchPaginatedRequest): Promise<void> {
    this.context.commit('updateLoadingStatus', true);
    const pagination = params.pagination || this.defaultPagination;
    const { offset, limit } = pagination;

    try {
      const { data } = await axios.get(
        `${Endpoints.Reports}/poultry`,
        {
          params: {
            keywords: params.keywords,
            offset,
            limit,
          },
        },
      );
      this.context.commit('updateReports', data.items);
      this.context.commit('updatePagination', data.pagination);
      this.context.commit('updatePaginateBy', 'searchListing');
    } catch (error) {
      this.context.commit('setError', error);
    }
    this.context.commit('updateLoadingStatus', false);
  }
}

export default getModule(ListReportStore);
