import { Farm, FarmState } from '@/models/poultry/farm.model';
import { ErrorResponse } from '@/models/poultry/response';
import { axios } from '@/utils/axios';
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule
} from 'vuex-module-decorators';
import { Endpoints } from '../endpoints';
import store from '../index';
import { clientStores } from '@/store/poultry/client';

export interface CreateFarmStoreInterface {

}
@Module({
  namespaced: true,
  dynamic: true,
  name: 'farm.create',
  store
})
class CreateFarmStore extends VuexModule {
  isLoading = false;
  isCreated = false;
  error: ErrorResponse | any = null;
  protected initialState: FarmState = {
    name: '',
    addressLine1: '',
    addressLine2: '',
    postcode: '',
    cityId: '',
    stateId: '',
    regionId: '',
    countryId: '',
  };

  farm: FarmState = { ...this.initialState };

  @Mutation
  setError(params: ErrorResponse) {
    this.error = params;
  }

  @Mutation
  updateLoadingStatus(params: boolean) {
    this.isLoading = params;
  }

  @Mutation
  setCreateFlag(params: boolean) {
    this.isCreated = params;
  }

  @Mutation
  updateState(params: Farm) {
    this.farm = { ...params };
  }

  @Mutation
  resetFarmState() {
    this.farm = { ...this.initialState }
  }

  @Action
  async create(): Promise<void> {
    this.context.commit('setCreateFlag', false);
    this.context.commit('updateLoadingStatus', true);
    try {
      await axios.post(`${Endpoints.Clients}/${clientStores.details.clientId}/farms`, this.farm);
      // TODO: Show toast or something
      this.context.commit('setCreateFlag', true);
      this.context.commit('updateState', this.initialState);
    } catch (error) {
      this.context.commit('setError', error);
      this.context.commit('setCreateFlag', false);
    }
    this.context.commit('updateLoadingStatus', false);
  }
}

export default getModule(CreateFarmStore);
