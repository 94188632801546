















































































































import { Component, Vue, Prop, Watch, Emit } from "vue-property-decorator";
import Dropdown from '@/components/poultry/Dropdown.vue';
import DropdownAlt from "@/components/poultry/DropdownAlt.vue";
import { DropdownOption } from '@/models/poultry/dropdown.model';
import { ReportTypeIds, ReportComparisonType } from '@/constants';

@Component({
  components: {
    Dropdown,
    DropdownAlt
  }
})
export default class DropdownCompare extends Vue {
  @Prop() sectionType!: [];
  @Prop() ipmSectionType!: [];
  @Prop() systemType!: [];
  @Prop() hasSection!: boolean;
  @Prop() hasSystem!: boolean;
  @Prop() reportTypeId !: string;
  @Prop()
  private selectedHouseType!: DropdownOption | string;
  @Prop()
  private selectedSystemType!: DropdownOption | string;
  @Prop()
  private selectedAuditType!: DropdownOption | string;
  @Prop()
  private selectedSectionType!: DropdownOption | string;
  @Prop()
  private selectedLesionType!: DropdownOption | string;
  @Prop()
  private selectedPeaLesionType!: DropdownOption | string;

  houseType = [
    {text: 'Broiler', value: '2'},
    {text: 'Layer/Breeder', value: '1'}
  ]

  auditType = [
    {text: 'All Audits', value: 'all'},
    {text: 'Fly Management', value: 'flyManagement'},
    {text: 'Rodents Control', value: 'rodentsControl'},
    {text: 'Darkling Beetles Control', value: 'darklingBeetlesControl'},
  ]

  ebaLesionsType = [
    { text: 'EARLY', value: 'early'},
    { text: 'MID', value: 'mid'},
    { text: 'LATE', value: 'late'},
    { text: 'PIP', value: 'pip'},
    { text: 'THIN', value: 'thin'},
    { text: 'USD', value: 'usd'},
    { text: 'BAC', value: 'bac'},
    { text: 'FUN', value: 'fun'},
    { text: 'CRA', value: 'cra'},
    { text: 'ANA', value: 'ana'},
  ]

  peaLesionsType = [
    { text: 'Airsac', value: 'airsac'},
    { text: 'Heart', value: 'heart'},
    { text: 'Liver', value: 'liver'},
    { text: 'Gizzard', value: 'gizzard'},
    { text: 'Yolk', value: 'yolk'}
  ]

  isActive = false;

  get comparisonType(){
    let comparisonType = [];

    comparisonType = [
      {text: 'clients', value: 'between_clients'},
      {text: 'farms per client', value: 'between_farms'}
    ];

    if (this.reportTypeId === ReportTypeIds.eba){
      comparisonType = [
        {text: 'hatchers per farm', value: ReportComparisonType.betweenHatchers },
        {text: 'trays per farm', value: ReportComparisonType.betweenTrays}
      ]
    }

    if (this.reportTypeId === ReportTypeIds.pea){
      comparisonType.push({ text : 'hatchers per farm', value: ReportComparisonType.betweenPeaHatchers })
    }

    return comparisonType;
  }

  get isCndReport(){
    return this.reportTypeId === ReportTypeIds.cnd;
  }

  get isIpmReport(){
    return this.reportTypeId === ReportTypeIds.ipm;
  }

  get isLowReport(){
    return this.reportTypeId === ReportTypeIds.labOnWheel;
  }

  get isEbaReport(){
    return this.reportTypeId === ReportTypeIds.eba;
  }

  get isPeaReport(){
    return this.reportTypeId === ReportTypeIds.pea;
  }

  mounted() {
    document.addEventListener('click', this.closeDropdown);
  }

  destroyed() {
    document.removeEventListener('click', this.closeDropdown);
  }

  closeDropdown(e: any) {
    if (!this.$el.contains(e.target)) {
      this.isActive = false;
    }
  }

  @Emit('onHandleComparisonTypeChanged')
  handleComparisonTypeChanged(value: DropdownOption) {}

  @Emit('onHandleSectionTypeChanged')
  handleSectionTypeChanged(value: DropdownOption) {}

  @Emit('onHandleAuditTypeChanged')
  handleAuditTypeChanged(value: DropdownOption) {}

  @Emit('onHandleSystemTypeChanged')
  handleSystemTypeChanged(value: DropdownOption) {}

  @Emit('onHandleHouseTypeChanged')
  handleHouseTypeChanged(value: DropdownOption) {}

  @Emit('onHandleLesionTypeChanged')
  handleLesionTypeChanged(value: DropdownOption) {}

  @Emit('onHandlePeaLesionTypeChanged')
  handlePeaLesionTypeChanged(value: DropdownOption) {}
}
