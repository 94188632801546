


















































































































































import { Component, Vue, Watch, Ref } from 'vue-property-decorator';
import DropdownCompare from '@/components/poultry/DropdownCompare.vue';
import Dropdown from '@/components/poultry/Dropdown.vue';
import DropdownFilter from '@/components/poultry/DropdownFilter.vue';
import DropdownFilterSearchBar from '@/components/poultry/DropdownFilterSearchBar.vue';
import { dropdownStores } from '@/store/poultry/dropdowns';
import { Region } from '@/models/poultry/country.model';
import { clientStores } from '@/store/poultry/client/index';
import { ClientResponse } from '@/models/poultry/client.model';
import { farmStores } from '@/store/poultry/farm';
import { auditorStores } from '@/store/poultry/auditor/index';
import { FarmResponse } from '@/models/poultry/farm.model';
import { AuditorResponse } from '@/models/poultry/auditor.model';
import VueMonthlyPicker from 'vue-monthly-picker';
import { analysisStores } from '@/store/poultry/analysis/index';
import ClientNameFilter from '@/views/poultry/Reports/ReportTableFilter/ClientNameFilter.vue';
import { reportStores } from '@/store/poultry/report/index';
import moment from 'moment'; // TODO: Need to use moment as the Month Picker use moment lib
import DropdownFilterAlt from '@/components/poultry/DropdownFilterAlt.vue';
import { DropdownOption } from '@/models/poultry/dropdown.model';
import { reportTypeStores } from '@/store/poultry/reportType';
import { ReportTypeIds } from '../../../../constants';

@Component({
  components: {
    DropdownCompare,
    DropdownFilter,
    DropdownFilterAlt,
    ClientNameFilter,
    Dropdown,
    VueMonthlyPicker,
    DropdownFilterSearchBar,
  },
})
export default class VaccinationAnalysisPageHeader extends Vue {
  defaultFormat = 'MM/YYYY';
  monthList = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'June',
    'July',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  clientStore = clientStores.list;
  clientDetailStore = clientStores.details;
  farmStore = farmStores.list;
  farmDetailStore = farmStores.details;
  auditorStore = auditorStores.list;
  auditorDetailStore = auditorStores.details;
  countryStore = dropdownStores.country;
  analysisStore = analysisStores.vaccination.list;
  reportStore = reportStores.list;
  reportTypeDetailStore = reportTypeStores.detail;
  isBetweenFarms = false;
  isSectionTypeSelected = false;
  clientNameData: Object[] = [];
  // clientIds: string[] = [];
  // selectedFilterData: string[] = [];
  defaultFilterType = 'state';
  defaultAnalysisType = 'between_clients';
  @Ref() resetSelectedData!: HTMLFormElement;
  @Ref() activeSelectedDropdown!: any;

  vaccination: any = {
    sectionType: null,
    clientIds: [],
    categoryType: null,
    filteredData: [],
    fromMonth: null,
    toMonth: null,
  };

  // This one would be a fixed value
  waterVaccinationData = [
    { text: 'Select a section', value: '' },
    { text: 'Timing', value: 'timetable' },
    { text: 'Reconstitution', value: 'reconstitution' },
    { text: 'Distribution', value: 'distribution' },
    { text: 'Assessment', value: 'assessment' },
    { text: 'Post-Vaccination', value: 'post_vaccination_steps' },
  ];

  injectaableVaccinationData = [
    { text: 'Select a section', value: '' },
    { text: 'Administration', value: 'administration' },
    { text: 'Assessment', value: 'assessment' },
    { text: 'Post-Vaccination', value: 'post_vaccination_steps' },
  ];

  // This one would be a fixed value
  categoryType = [
    { text: 'State', value: 'state' },
    { text: 'Farm', value: 'farm' },
    { text: 'Auditor', value: 'auditor' },
  ];

  // Init data in all store by fetchAll
  async created() {
    const species = (this.$router.currentRoute.fullPath.split('/'))[1];

    this.clientStore.setSpecies(species)
    await this.clientStore.fetchClients();
    await this.countryStore.fetchCountries();
    await this.countryStore.fetchStatesByCountryCode();
    this.clientNameData = this.clientsName;
  }

  get minMonth() {
    if (this.vaccination.fromMonth) {
      const oneMonthAfter = moment(this.vaccination.fromMonth).add(1, 'months');
      return oneMonthAfter;
    }
    return moment(new Date()).add(1, 'months');
  }

  get maxMonth() {
    if (this.vaccination.fromMonth) {
      const oneYear = moment(this.vaccination.fromMonth).add(11, 'months');
      return oneYear;
    }
    return moment(new Date()).add(11, 'months');
  }

  @Watch('vaccination.fromMonth')
  clearMaxMonth() {
    if (this.vaccination.fromMonth) {
      this.vaccination.toMonth = null;
    }
  }

  get isCategoryTypeSelected() {
    return !this.vaccination.categoryType;
  }

  get disableDropdown() {
    return !this.vaccination.clientIds.length;
  }

  // To get list of clients in dropdown filter
  get clients(): ClientResponse[] {
    return this.clientStore.clientsDropdown;
  }

  get clientsName() {
    return Object.values(this.clients).map((client) => {
      return {
        text: client.name,
        value: String(client.id),
      };
    });
  }

  async searchByClientName(inputClientName: any) {
    // Filter the Client Objects here.
    if (!inputClientName.trim()) {
      this.clientNameData = this.clientsName;
    } else {
      await this.clientStore.searchByKeywords({
        keywords: inputClientName
      });

      this.clientNameData = this.clientStore.clients.map(client => {
        return {
          text: client.name,
          value: String(client.id)
        };
      });

    }
  }

  // To get list of states in dropdown filter
  get regions(): Region[] {
    return this.countryStore.regions;
  }

  get regionsName() {
    return Object.values(this.regions).map(({ name, id}: any) => {
      return {
        text: name,
        value: id,
      };
    });
  }

  // To get list of farms in dropdown filter
  get farms(): FarmResponse[] {
    return this.farmDetailStore.farmsDropdown;
  }

  get farmsName() {
    return Object.values(this.farms).map((farm) => {
      return {
        text: farm.name,
        value: String(farm.id),
      }
    });
  }

  // To get list of auditors in dropdown filter
  get auditors(): AuditorResponse[] {
    return this.auditorDetailStore.auditorsDropdown;
  }

  get auditorsName() {
    return Object.values(this.auditors).map((auditor) => {
      return {
        text: auditor.userProfile.name,
        value: String(auditor.id),
      }
    });
  }

  resetVaccinationAnalysisCreated() {
    if (this.analysisStore.isCreated) {
      return this.analysisStore.setVaccinationCreated(false);
    }
  }

  // // This watcher is to reset value in category and selection
  @Watch('analysisStore.clientName')
  clearCategoryAndSelections() {
    this.vaccination.categoryType = null;
    this.resetSelectedData['selectedData'] = [];
  }

  // First Dropdown compare filter: Values are between_clients and between_farms
  comparisonTypeChanged(comparisonType: DropdownOption) {
    this.resetVaccinationAnalysisCreated;
    if (comparisonType.value === 'between_farms') {
      this.isBetweenFarms = true;
      this.defaultAnalysisType = comparisonType.value;
      this.vaccination.clientIds = [];
    } else {
      this.isBetweenFarms = false;
      this.defaultAnalysisType = comparisonType.value;
      this.vaccination.clientIds = [];
    }
  }

  // Second Dropdown compare filter: Different values between Preparation and Vaccination
  sectionTypeChanged(sectionType: DropdownOption) {
    this.resetVaccinationAnalysisCreated;
    if (sectionType.value === '') {
      this.isSectionTypeSelected = false;
    } else {
      this.isSectionTypeSelected = true;
      this.vaccination.sectionType = sectionType.text;
      this.analysisStore.setSectionType(sectionType);
    }
  }

  // First Dropdown filter: List of Clients
  handleClientChanged(params: DropdownOption) {
    this.resetVaccinationAnalysisCreated;
    this.activeSelectedDropdown['isActive'] = true;
    this.vaccination.clientIds = [];
    this.analysisStore.setClientName(params);
    this.vaccination.clientIds.push(params.value);
  }

  // Second Dropdown filter: Values are Farm, Auditor and State
  handleCategoryTypeChanged(params: DropdownOption) {
    this.resetSelectedData['selectedData'] = [];
    this.clientDetailStore.setClientId(Number(this.vaccination.clientIds.toString()));
    this.vaccination.categoryType = params.value;
    if (params.value === 'farm') {
      this.farmDetailStore.fetchFarmsDropdown({
        clientId: this.vaccination.clientIds[0],
        cities: [],
        state: '',
      });
    }
    if (params.value === 'auditor') {
      this.auditorDetailStore.fetchAuditorsDropdown(this.vaccination.clientIds[0]);
    }
  }

  // Third Dropdown filter: Watch if there are changes (selected) in State, Farm and Auditor Dropdown
  // then call the respective API for data listing in dropdown filter
  @Watch('defaultFilterType')
  onChangeCategoryType() {
    if (this.vaccination.categoryType === 'state') {
      return this.regionsName;
    }
    if (this.vaccination.categoryType === 'farm') {
      return this.farmsName;
    }
    if (this.vaccination.categoryType === 'auditor') {
      return this.auditorsName;
    }
  }

  filterClientName(filteredData: []) {
    this.vaccination.clientIds = [...filteredData];
  }

  onCloseClientNameDropdown(selectedData: []) {
    this.vaccination.clientIds = [...selectedData];
  }

  filterCategoryName(filteredData: []) {
    this.vaccination.filteredData = [...filteredData];
  }

  onCloseCategoryTypeDropdown(selectedData: []) {
    this.vaccination.filteredData = [...selectedData];
  }

  // When generate button is clicked
  handleGenerateReport() {
    this.analysisStore.setAnalysisType(this.defaultAnalysisType);
    this.analysisStore.setStartDate(moment(this.vaccination.fromMonth!).format('YYYY-MM'));
    this.analysisStore.setEndDate(moment(this.vaccination.toMonth!).format('YYYY-MM'));
    this.analysisStore.setClientIds(this.vaccination.clientIds);
    this.analysisStore.setReportTypeId(this.reportTypeDetailStore.reportTypeId);
    // TODO: Refactor
    if (this.vaccination.categoryType === 'state') {
      this.analysisStore.setStates(this.vaccination.filteredData);
    }
    if (this.vaccination.categoryType === 'farm') {
      this.analysisStore.setFarms(this.vaccination.filteredData);
    }
    if (this.vaccination.categoryType === 'auditor') {
      this.analysisStore.setAuditors(this.vaccination.filteredData);
    }
    //
    this.analysisStore.setCategoryType(this.vaccination.categoryType);
    this.analysisStore.fetchAll();
  }

  get vaccinations() {
    return this.analysisStore.vaccination;
  }

  @Watch('vaccination')
  get dropdownIsValid(): boolean {
    const excludedKeys: string[] = [];

    // Push key that doesn't have in between clients to excludedkeys array
    if (this.defaultAnalysisType === 'between_clients') {
      excludedKeys.push('categoryType');
      excludedKeys.push('filteredData');
    }

    // Filter keys that have same value as in the result object, exclude it and put it in new array
    const keys = Object.keys(this.vaccination).filter(
      (key) => excludedKeys.includes(key) === false,
    );

    // Check each keys whether got values or empty
    const values = keys.map((key) => {
      // if no values in array of clientIds or in filteredData return false
      if (
        (key === 'clientIds' && !this.vaccination[key].length) ||
        (key === 'filteredData' && !this.vaccination[key].length)
      ) {
        return false;
      }
      return this.vaccination[key];
    });
    return values.every(Boolean);
  }

   get isWaterReport(){
    return this.reportTypeDetailStore.reportTypeId.toString() === ReportTypeIds.water;
  }

  get isInjectableReport(){
    return this.reportTypeDetailStore.reportTypeId.toString() === ReportTypeIds.injectable;
  }
}
