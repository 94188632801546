import { Auditor, AuditorState, CreateAuditorResponse } from '@/models/poultry/auditor.model';
import { ErrorResponse } from '@/models/poultry/response';
import { axios } from '@/utils/axios';
import {
  Action,
  getModule,
  Module,
  Mutation,
  MutationAction,
  VuexModule
} from 'vuex-module-decorators';
import { Endpoints } from '../endpoints';
import store from '../index';
import { clientStores } from '@/store/poultry/client/index';
import { AuditorResponse } from '@/models/poultry/auditor.model';

export interface CreateClientStoreInterface {
  clientId: number;
  response: CreateAuditorResponse | null;
  isLoading: boolean;
  error: ErrorResponse | null;
  create: () => void;
}
@Module({
  namespaced: true,
  dynamic: true,
  name: 'auditor.create',
  store
})
class CreateAuditorStore extends VuexModule {
  isLoading = false;
  isUpdated = false;
  error: ErrorResponse | null = null;
  selectedAuditors: number[] = [];
  keywords: string = '';
  allAuditors: AuditorResponse[] = [];

  @Mutation
  setError(params: ErrorResponse) {
    this.error = params;
  }

  @Mutation
  updateLoadingStatus(params: boolean) {
    this.isLoading = params;
  }

  @Mutation
  setIsUpdated(params: boolean) {
    this.isUpdated = params;
  }

  @Mutation
  setAuditors(params: AuditorResponse[]) {
    this.allAuditors = params;
  }

  @Mutation
  setSelectedAuditors(params: number[]) {
    this.selectedAuditors = params;
  }

  @Mutation
  setKeywords(params: string) {
    this.keywords = params;
  }

  @Action
  async fetchAllAuditors(): Promise<void> {
    this.context.commit('updateLoadingStatus', true);
    try {
      const { data } = await axios.get(
        `${Endpoints.Auditors}`,
        {
          params: {
            keywords: this.keywords,
            sortBy: 'email:asc'
          },
        },
      );

      this.context.commit('setAuditors', data);
    } catch (error) {
      this.context.commit('setError', error);
    }
    this.context.commit('updateLoadingStatus', false);
  }

  @Action
  async fetchSelectedAuditors(): Promise<void> {
    this.context.commit('updateLoadingStatus', true);
    try {
      const { data } = await axios.get(`${Endpoints.Clients}/${clientStores.details.clientId}/auditors`);

      const auditorIds: number[] = []

      data.auditors.forEach((auditor: any) => {
        auditorIds.push(auditor.id)
      })

      this.context.commit('setSelectedAuditors', auditorIds);
    } catch (error) {
      this.context.commit('setError', error);
    }
    this.context.commit('updateLoadingStatus', false);
  }

  @Action
  async updateClientAuditor(): Promise<void> {
    this.context.commit('setIsUpdated', false);
    this.context.commit('updateLoadingStatus', true);

    try {
      await axios.post(`${Endpoints.Clients}/${clientStores.details.clientId}/auditors`, {
        clientId: clientStores.details.clientId,
        auditorIds: this.selectedAuditors
      });
      // TODO: Show toast or something
      this.context.commit('setIsUpdated', true);
    } catch (error) {
      this.context.commit('setError', error);
    }
    this.context.commit('updateLoadingStatus', false);
  }
}

export default getModule(CreateAuditorStore);
