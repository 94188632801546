


























































































































import { Component, Vue, Prop, Watch, Emit } from "vue-property-decorator";
import { DropdownOption } from '@/models/poultry/dropdown.model';
import { ReportTypeIds, ReportComparisonType } from '@/constants';

@Component({})

export default class DropdownFilterSearchBar extends Vue {
    @Prop() options!: DropdownOption[];
    @Prop() filterType!: string;
    @Prop() disabled!: boolean;
    @Prop() defaultPlaceholder!: string;
    @Prop() selectedOption!: DropdownOption[];
    @Prop() reportTypeId!: string;
    @Prop() analysisType!: ReportComparisonType;

    trayNumberKeywords: string = '';
    clientKeywords: string = '';
    isActive: any = false;
    isTooltipActive: any = false;
    selected: any = null;
    selectedData: any[] = [];

    created() {
        this.selectedData = [];
        this.initializeDefaultOption();
    }

    mounted() {
        document.addEventListener("click", this.closeDropdown);
    }

    get isEbaReport(){
        return this.reportTypeId === ReportTypeIds.eba;
    }

    get isCompareBetweenTrays(){
        return this.analysisType === ReportComparisonType.betweenTrays;
    }

    getReportStyleByReportTypeId(reportTypeId: string): string{
        let style = '';

        switch(reportTypeId){
        case ReportTypeIds.water:
            style = 'water';
            break;
        case ReportTypeIds.injectable:
            style = 'injectable';
            break;
        case ReportTypeIds.hatchery:
            style = 'hatchery';
            break;
        case ReportTypeIds.cnd:
            style = 'cleaning';
            break;
        case ReportTypeIds.ipm:
            style = 'ipm';
            break;
        case ReportTypeIds.labOnWheel:
            style = 'low';
            break;
        case ReportTypeIds.eba:
            style = 'eba';
            break;
        case ReportTypeIds.pea:
            style = 'pea';
            break;
        default:
            style = '';
            break;
        }
        return style;
    }

    destroyed() {
        document.removeEventListener("click", this.closeDropdown);
    }

    activateDropdown() {
        this.isActive = true;
        this.isTooltipActive = false;
    }

    changeButtonColor(){
        return this.getReportStyleByReportTypeId(this.reportTypeId);
    }

    changeCheckBoxColor(){
        let checkboxColor = this.getReportStyleByReportTypeId(this.reportTypeId);
        return `is-${checkboxColor}`;
    }

    changeInvertedColor(){
        let invertedColor = this.getReportStyleByReportTypeId(this.reportTypeId);
        return `${invertedColor}-inverted`;
    }

    @Emit('onFilterData')
    clearData() {
        return this.selectedData = [];
    }

    @Emit('onFilterData')
    filterData(filteredData: []) {
        return filteredData;
    }

    @Emit('onSearch')
    searchByClientName() {
        return this.clientKeywords;
    }

    @Emit('onSearch')
    searchByTrayNumber() {
        return this.trayNumberKeywords;
    }

    @Emit('onClose')
    closeDropdown(e: any) {
        if (!this.$el.contains(e.target) || e.target.id === 'save-filter--button' || e.target.id === 'clear-filter--button') {
            this.isActive = false;
        }
        return this.selectedData;
    }

    initializeDefaultOption() {
        if (this.selectedOption) {
            this.selectedData = this.selectedOption;
        }
    }
}
