import { ErrorResponse } from '@/models/poultry/response';
import { axios } from '@/utils/axios';
import { Action, getModule, Module, Mutation, MutationAction, VuexModule } from 'vuex-module-decorators';
import { DropdownOption } from '@/models/poultry/dropdown.model';
import { Endpoints } from '@/store/poultry/endpoints';
import store from '@/store/poultry/index';
import { AnalysisData, ScoreAnalysis } from '@/models/poultry/analysis.model';
import arraySort from 'array-sort';
@Module({
  namespaced: true,
  dynamic: true,
  name: 'swab_samples.list',
  store
})
class SwabSampleAnalysisListStore extends VuexModule {
  isLoading = false;
  isCreated = false;
  swab_sample = [];
  chartStyle: string = 'horizontalBar';
  error: ErrorResponse | null = null;
  title: string | null = null;
  analysisType: string | null = null;
  reportTypeId: number | null = null;
  stateIds: string[] = [];
  farmIds: string[] = [];
  auditorIds: string[] = [];
  clientIds: string[] = [];
  clientName: string | null = null;
  startDate: string | null = null;
  endDate: string | null = null;
  categoryType: string | null = null;
  houseTypeText: string | null = 'Broiler';
  houseTypeValue: string | null = '2';
  currentSort: keyof AnalysisData = 'name';
  currentSortOrder = { reverse: true }; // false is asc, true is desc

  get hasSwabSample(): boolean {
    return this.isLoading === false && Boolean(this.swab_sample.length);
  }

  get sortedSwabSample() {
    let swab_sampleCopy: AnalysisData[] = [...this.swab_sample];
    let finalSwabSample: AnalysisData[] = [];

    swab_sampleCopy.forEach(items => {
      let data = items.data.reduce((data: any, currentData: ScoreAnalysis) => {
        // if highest not empty or if current score greater, in highest
        if (!data.highestReport || currentData.score > data.highestReport.score) {
          data = {...data, highestReport : currentData};
        }
        // if have same highest score append month together
        else if (currentData.score === data.highestReport.score) {
          data = {
            ...data,
            highestReport: {
              ...data.highestReport,
              publishedMonth: data.highestReport.publishedMonth+ ', '+currentData.publishedMonth
            }
          }
        }

        // if lowest not empty or if current score lower, in lowest
        if (!data.lowestReport || currentData.score < data.lowestReport.score) {
          data = {...data, lowestReport : currentData};
        }
        // if have same lowest score append month together
        else if (currentData.score === data.lowestReport.score) {
          data = {
            ...data,
            lowestReport: {
              ...data.lowestReport,
              publishedMonth: data.lowestReport.publishedMonth+ ', '+currentData.publishedMonth
            }
          }
        }
        return data;
      }, {});
      // For Others row, that got score 0
      data = {
        highestReport : {
          score: 0,
          publishedMonth:'-'
        },
        lowestReport: {
          score: 0,
          publishedMonth:'-'
        },
        ...data,
      }
      // Append data object in items
      items = {
        ...items,
        ...data
      }
      // Push items to array
      finalSwabSample.push(items);
    });
    return arraySort(finalSwabSample, this.currentSort, this.currentSortOrder);
  }

  @Mutation
  setSortType(params: any) {
    if (params === this.currentSort) {
      this.currentSortOrder.reverse = !this.currentSortOrder.reverse;
    }
    this.currentSort = params;
  }

  @Mutation
  updateLoadingStatus(params: boolean) {
    this.isLoading = params;
  }

  @Mutation
  updateSwabSample(params: any) {
    this.swab_sample = params;
  }

  @Mutation
  setAnalysisType(params: string) {
    this.analysisType = params;
  }

  @Mutation
  setStartDate(params: string) {
    this.startDate = params;
  }

  @Mutation
  setEndDate(params: string) {
    this.endDate = params;
  }

  @Mutation
  setClientIds(params: string[]) {
    this.clientIds = params;
  }

  @Mutation
  setClientName(params: DropdownOption) {
    this.clientName = params.text;
  }

  @Mutation
  setCategoryType(params: string) {
    this.categoryType = params;
  }

  @Mutation
  setHouseType(params: DropdownOption) {
    this.houseTypeText = params.text;
    this.houseTypeValue = params.value;
  }

  @Mutation
  setStates(params: string[]) {
    this.stateIds = params;
  }

  @Mutation
  setFarms(params: string[]) {
    this.farmIds = params;
  }

  @Mutation
  setAuditors(params: string[]) {
    this.auditorIds = params;
  }

  @Mutation
  setError(params: ErrorResponse) {
    this.error = params;
  }

  @Mutation
  setSwabSampleCreated(params: boolean) {
    this.isCreated = params;
  }

  @Mutation
  setReportTypeId(params: any) {
    this.reportTypeId = Number(params)
  }

  @Mutation
  setChartStyle(params: string) {
    this.chartStyle = params;
  }

  // TODO: Refactor
  @Action
  async fetchAll() {
    this.context.commit('updateLoadingStatus', true);
    this.context.commit('setSwabSampleCreated', false);
    try {
      if (this.analysisType === 'between_clients') {
        const { data } = await axios.get(Endpoints.SwabSampleAnalysis, {
          params: {
            analysis_type: this.analysisType,
            houseType: this.houseTypeValue,
            client_ids: this.clientIds.toString(),
            report_type_id: this.reportTypeId,
            start_date: this.startDate,
            end_date: this.endDate,
          }
        });
        this.context.commit('updateSwabSample', data);
        this.context.commit('setSwabSampleCreated', true);
      }

      if (this.analysisType === 'between_farms') {
        if (this.categoryType === 'state') {
          const { data } = await axios.get(Endpoints.SwabSampleAnalysis, {
            params: {
              analysis_type: this.analysisType,
              houseType: this.houseTypeValue,
              client_id: this.clientIds.toString(),
              report_type_id: this.reportTypeId,
              start_date: this.startDate,
              end_date: this.endDate,
              state_ids: this.stateIds.toString(),
            }
          });
          this.context.commit('updateSwabSample', data);
          this.context.commit('setSwabSampleCreated', true);
        }
  
        if (this.categoryType === 'farm') {
          const { data } = await axios.get(Endpoints.SwabSampleAnalysis, {
            params: {
              analysis_type: this.analysisType,
              houseType: this.houseTypeValue,
              client_id: this.clientIds.toString(),
              report_type_id: this.reportTypeId,
              start_date: this.startDate,
              end_date: this.endDate,
              farm_ids: this.farmIds.toString(),
            }
          });
          this.context.commit('updateSwabSample', data);
          this.context.commit('setSwabSampleCreated', true); 
        }
  
        if (this.categoryType === 'auditor') {
          const { data } = await axios.get(Endpoints.SwabSampleAnalysis, {
            params: {
              analysis_type: this.analysisType,
              houseType: this.houseTypeValue,
              client_id: this.clientIds.toString(),
              report_type_id: this.reportTypeId,
              start_date: this.startDate,
              end_date: this.endDate,
              auditor_ids: this.auditorIds.toString(),
            }
          });
          this.context.commit('updateSwabSample', data);
          this.context.commit('setSwabSampleCreated', true); 
        }
      }

    } catch(error) {
      this.context.commit('setError', error);
    }
    this.context.commit('updateLoadingStatus', false);
  }
}

export default getModule(SwabSampleAnalysisListStore);
