



















import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { analysisStores } from '@/store/poultry/analysis';
import dayjs, {Dayjs} from 'dayjs';

@Component({
  components: {}
})
export default class VaccinationAnalysisChartHeader extends Vue {
  store = analysisStores.vaccination.list;
  date: string | null = null;

  get vaccination() {
    return this.store.vaccination;
  }

  get categoryType() {
    return this.store.categoryType;
  }

  get startDate() {
    let date: any = this.store.startDate;
    return dayjs(date).format('MMM YYYY');
  }

  get endDate() {
    let date: any = this.store.endDate;
    return dayjs(date).format('MMM YYYY');
  }

  get clientName() {
    return this.store.clientName;
  }

  get analysisType() {
    return this.store.analysisType;
  }

  get sectionType() {
    return this.store.sectionTypeText;
  }

  handleExportButtonClicked() {
    window.print();
  }
}
