import { AuditorResponse } from '@/models/poultry/auditor.model';
import { PaginationModel } from '@/models/poultry/pagination.model';
import { SearchPaginatedRequest } from '@/models/poultry/requests';
import { ErrorResponse } from '@/models/poultry/response';
import { clientStores } from '@/store/poultry/client/index';
import { axios } from '@/utils/axios';
import arraySort from 'array-sort';
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { DEFAULT_COUNT_PER_PAGE } from '@/constants';
import { PaginatedResponse } from '@/models/poultry/response';
import { Endpoints } from '../endpoints';
import store from '../index';
export interface ListAuditorStoreInterface {}
@Module({
  namespaced: true,
  dynamic: true,
  name: 'auditor.list',
  store,
})
class ListAuditorStore extends VuexModule {
  isLoading = false;
  error: ErrorResponse | null = null;
  auditors: AuditorResponse[] = [];
  pagination = { ...this.defaultPagination };

  currentSort: keyof AuditorResponse = 'createdDate';
  currentSortOrder = { reverse: true }; // false is asc, true is desc

  get defaultPagination(): PaginationModel {
    return {
      offset: 1,
      limit: DEFAULT_COUNT_PER_PAGE,
    };
  }

  get hasAuditors(): boolean {
    return this.isLoading === false && Boolean(this.auditors.length);
  }

  get sortedAuditors() {
    const auditorsCopy = [...this.auditors];
    return arraySort(auditorsCopy, this.currentSort, this.currentSortOrder);
  }

  @Mutation
  setSortType(params: any) {
    if (params === this.currentSort) {
      this.currentSortOrder.reverse = !this.currentSortOrder.reverse;
    }
    this.currentSort = params;
  }

  @Mutation
  updateAuditors(params: AuditorResponse[]) {
    this.auditors = params;
  }

  @Mutation
  updatePagination(params: PaginationModel) {
    this.pagination = params;
  }

  @Mutation
  setError(params: ErrorResponse) {
    this.error = params;
  }

  @Mutation
  updateLoadingStatus(params: boolean) {
    this.isLoading = params;
  }

  @Action
  async fetchAll(
    params: PaginationModel = { offset: 1, limit: DEFAULT_COUNT_PER_PAGE },
  ): Promise<void> {
    this.context.commit('updateLoadingStatus', true);
    const { offset, limit } = params;
    
    try {
      const response = await axios.get(
        `${Endpoints.Clients}/${clientStores.details.clientId}/auditors`,
        {
          params: {
            offset,
            limit,
          },
        },
      );

      const { auditors, pagination } = response.data;

      this.context.commit('updateAuditors', auditors);
      this.context.commit('updatePagination', pagination);
    } catch (error) {
      this.context.commit('setError', error);
    }
    this.context.commit('updateLoadingStatus', false);
  }

  @Action
  async searchByKeywords(params: SearchPaginatedRequest) {
    this.context.commit('updateLoadingStatus', true);
    const pagination = params.pagination || this.defaultPagination;
    const { offset, limit } = pagination;

    try {
      const response = await axios.get(
        `${Endpoints.Clients}/${clientStores.details.clientId}/auditors`,
        {
          params: {
            keywords: params.keywords,
            offset,
            limit,
          },
        },
      );

      const { auditors, pagination } = response.data;

      this.context.commit('updateAuditors', auditors);
      this.context.commit('updatePagination', pagination);
    } catch (error) {
      this.context.commit('setError', error);
    }
    this.context.commit('updateLoadingStatus', false);
  }
}

export default getModule(ListAuditorStore);
