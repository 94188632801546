export const phase2_chart = {
  type: 'line',
  options: {
    tooltips: {
      mode: 'index',
      intersect: false,
    },
    hover: {
      mode: 'nearest',
      intersect: true,
    },
    legend: {
      display: true,
      position: 'bottom',
      labels: {
        padding: 50,
        usePointStyle: true,
        boxWidth: 12,
        backgroundColor: 'red',
        fontSize: 16,
      },
    },
    responsive: true,
    lineTension: 1,
    scales: {
      xAxes: [
        {
          gridLines: {
            display: false,
          },
          scaleLabel: {
            display: true,
            labelString: 'Month',
            fontStyle: 'bold',
            fontSize: 16,
          },
          ticks: {
            fontColor: '#8E8E8E',
            fontSize: 16,
            beginAtZero: true,
            padding: 25,
          },
        },
      ],
      yAxes: [
        {
          gridLines: {
            drawBorder: false,
            borderDash: [5, 5],
            color: '#e3e3e3',
            borderDashOffset: 0.5,
          },
          scaleLabel: {
            display: true,
            labelString: 'Avg Audit Scoring (%)',
            fontStyle: 'bold',
            fontSize: 16,
          },
          ticks: {
            fontColor: '#8E8E8E',
            fontSize: 16,
            beginAtZero: true,
            padding: 25,
          },
        },
      ],
    },
    plugins: {
      datalabels: {
         // hide datalabels for all datasets
         display: false
      }
    }
  },
};

export const phase2_bar_chart = {
  type: 'horizontalBar',
  options: {
    tooltips: {
      mode: 'index',
      intersect: false,
    },
    hover: {
      mode: 'nearest',
      intersect: true,
    },
    legend: {
      display: true,
      position: 'bottom',
      labels: {
        padding: 50,
        usePointStyle: true,
        boxWidth: 12,
        fontSize: 16,
      },
    },
    responsive: true,
    lineTension: 1,
    scales: {
      xAxes: [
        {
          gridLines: {
            drawBorder: false,
            borderDash: [5, 5],
            color: '#e3e3e3',
            borderDashOffset: 0.5,
          },
          scaleLabel: {
            display: true,
            labelString: 'Avg Task Performance Score (%)',
            fontStyle: 'bold',
            fontSize: 16,
          },
          ticks: {
            fontColor: '#8E8E8E',
            fontSize: 16,
            beginAtZero: true,
            padding: 25,
          },
        },
      ],
      yAxes: [
        {
          gridLines: {
            display: false,
          },
          scaleLabel: {
            display: true,
            labelString: 'Month',
            fontStyle: 'bold',
            fontSize: 16,
          },
          ticks: {
            fontColor: '#8E8E8E',
            beginAtZero: true,
            fontSize: 16,
          },
        },
      ],
    },
    plugins: {
      datalabels: {
         // hide datalabels for all datasets
         display: false
      }
    }
  },
}

export const phase3_chart = {
  type: 'horizontalBar',
  options: {
    layout: {
      padding: {
      right: 50
      }
    },
    tooltips: {
      mode: 'index',
      intersect: false,
    },
    hover: {
      mode: 'nearest',
      intersect: true,
    },
    legend: {
      display: true,
      position: 'bottom',
      labels: {
        padding: 50,
        usePointStyle: true,
        boxWidth: 12,
        fontSize: 16,
      },
    },
    maintainAspectRatio: false,
    lineTension: 1,
    scales: {
      xAxes: [
        {
          gridLines: {
            drawBorder: false,
            borderDash: [5, 5],
            color: '#e3e3e3',
            borderDashOffset: 0.5,
          },
          scaleLabel: {
            display: true,
            labelString: 'Avg Task Performance Score (%)',
            fontStyle: 'bold',
            fontSize: 16,
          },
          ticks: {
            fontColor: '#8E8E8E',
            fontSize: 16,
            beginAtZero: true,
            padding: 25,
          },
        },
      ],
      yAxes: [
        {
          gridLines: {
            display: false,
          },
          scaleLabel: {
            display: true,
            labelString: 'Month',
            fontStyle: 'bold',
            fontSize: 16,
          },
          ticks: {
            fontColor: '#8E8E8E',
            beginAtZero: true,
            fontSize: 16,
          },
        },
      ],
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
        formatter: function (value: number) {
          return `${value}%`;
        },
        color: '#8E8E8E',
        font: {
          size: 16,
          family: 'Open Sans'
        }
      },
    },
  },
};

export const phase4_low__horizontal_chart = {
  type: 'horizontalBar',
  options: {
    layout: {
      padding: {
      right: 50
      }
    },
    tooltips: {
      mode: 'index',
      intersect: false,
    },
    hover: {
      mode: 'nearest',
      intersect: true,
    },
    legend: {
      display: true,
      position: 'bottom',
      labels: {
        padding: 50,
        usePointStyle: true,
        boxWidth: 12,
        fontSize: 16,
      },
    },
    maintainAspectRatio: false,
    lineTension: 1,
    scales: {
      xAxes: [
        {
          gridLines: {
            drawBorder: false,
            borderDash: [5, 5],
            color: '#e3e3e3',
            borderDashOffset: 0.5,
          },
          scaleLabel: {
            display: true,
            labelString: 'Avg Risk Level Result (%)',
            fontStyle: 'bold',
            fontSize: 16,
          },
          ticks: {
            fontColor: '#8E8E8E',
            fontSize: 16,
            beginAtZero: true,
            padding: 25,
          },
        },
      ],
      yAxes: [
        {
          gridLines: {
            display: false,
          },
          scaleLabel: {
            display: true,
            labelString: 'Month',
            fontStyle: 'bold',
            fontSize: 16,
          },
          ticks: {
            fontColor: '#8E8E8E',
            beginAtZero: true,
            fontSize: 16,
          },
        },
      ],
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
        formatter: function (value: number) {
          return `${value}%`;
        },
        color: '#8E8E8E',
        font: {
          size: 16,
          family: 'Open Sans'
        }
      },
    },
  },
};

export const phase4_low_chart = {
  type: 'line',
  options: {
    tooltips: {
      mode: 'index',
      intersect: false,
    },
    hover: {
      mode: 'nearest',
      intersect: true,
    },
    legend: {
      display: true,
      position: 'bottom',
      labels: {
        padding: 50,
        usePointStyle: true,
        boxWidth: 12,
        backgroundColor: 'red',
        fontSize: 16,
      },
    },
    responsive: true,
    lineTension: 1,
    scales: {
      xAxes: [
        {
          gridLines: {
            display: false,
          },
          scaleLabel: {
            display: true,
            labelString: 'Month',
            fontStyle: 'bold',
            fontSize: 16,
          },
          ticks: {
            fontColor: '#8E8E8E',
            fontSize: 16,
            beginAtZero: true,
            padding: 25,
          },
        },
      ],
      yAxes: [
        {
          gridLines: {
            drawBorder: false,
            borderDash: [5, 5],
            color: '#e3e3e3',
            borderDashOffset: 0.5,
          },
          scaleLabel: {
            display: true,
            labelString: 'Avg Risk Level Result (%)',
            fontStyle: 'bold',
            fontSize: 16,
          },
          ticks: {
            fontColor: '#8E8E8E',
            fontSize: 16,
            beginAtZero: true,
            padding: 25,
          },
        },
      ],
    },
    plugins: {
      datalabels: {
         // hide datalabels for all datasets
         display: false
      }
    }
  },
};

export const phase4_eba_horizontal_chart = {
  type: 'horizontalBar',
  options: {
    layout: {
      padding: {
      right: 50
      }
    },
    tooltips: {
      mode: 'index',
      intersect: false,
    },
    hover: {
      mode: 'nearest',
      intersect: true,
    },
    legend: {
      display: true,
      position: 'bottom',
      labels: {
        padding: 50,
        usePointStyle: true,
        boxWidth: 12,
        fontSize: 16,
      },
    },
    maintainAspectRatio: false,
    lineTension: 1,
    scales: {
      xAxes: [
        {
          gridLines: {
            drawBorder: false,
            borderDash: [5, 5],
            color: '#e3e3e3',
            borderDashOffset: 0.5,
          },
          scaleLabel: {
            display: true,
            labelString: 'Avg Lesion Percentage (%)',
            fontStyle: 'bold',
            fontSize: 16,
          },
          ticks: {
            fontColor: '#8E8E8E',
            fontSize: 16,
            beginAtZero: true,
            padding: 25,
          },
        },
      ],
      yAxes: [
        {
          gridLines: {
            display: false,
          },
          scaleLabel: {
            display: true,
            labelString: 'Month',
            fontStyle: 'bold',
            fontSize: 16,
          },
          ticks: {
            fontColor: '#8E8E8E',
            beginAtZero: true,
            fontSize: 16,
          },
        },
      ],
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
        formatter: function (value: number) {
          return `${value}%`;
        },
        color: '#8E8E8E',
        font: {
          size: 16,
          family: 'Open Sans'
        }
      },
    },
  },
};

export const phase4_eba_chart = {
  type: 'line',
  options: {
    tooltips: {
      mode: 'index',
      intersect: false,
    },
    hover: {
      mode: 'nearest',
      intersect: true,
    },
    legend: {
      display: true,
      position: 'bottom',
      labels: {
        padding: 50,
        usePointStyle: true,
        boxWidth: 12,
        backgroundColor: 'red',
        fontSize: 16,
      },
    },
    responsive: true,
    lineTension: 1,
    scales: {
      xAxes: [
        {
          gridLines: {
            display: false,
          },
          scaleLabel: {
            display: true,
            labelString: 'Month',
            fontStyle: 'bold',
            fontSize: 16,
          },
          ticks: {
            fontColor: '#8E8E8E',
            fontSize: 16,
            beginAtZero: true,
            padding: 25,
          },
        },
      ],
      yAxes: [
        {
          gridLines: {
            drawBorder: false,
            borderDash: [5, 5],
            color: '#e3e3e3',
            borderDashOffset: 0.5,
          },
          scaleLabel: {
            display: true,
            labelString: 'Avg Lesion Percentage (%)',
            fontStyle: 'bold',
            fontSize: 16,
          },
          ticks: {
            fontColor: '#8E8E8E',
            fontSize: 16,
            beginAtZero: true,
            padding: 25,
          },
        },
      ],
    },
    plugins: {
      datalabels: {
         // hide datalabels for all datasets
         display: false
      }
    }
  },
};