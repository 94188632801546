import { render, staticRenderFns } from "./DropdownFilterAlt.vue?vue&type=template&id=4548d63e&scoped=true&"
import script from "./DropdownFilterAlt.vue?vue&type=script&lang=ts&"
export * from "./DropdownFilterAlt.vue?vue&type=script&lang=ts&"
import style0 from "./DropdownFilterAlt.vue?vue&type=style&index=0&id=4548d63e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4548d63e",
  null
  
)

export default component.exports