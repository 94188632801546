








import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import VaccinationAnalysisTableHeader from './VaccinationAnalysisTableHeader.vue';
import VaccinationAnalysisTableBody from './VaccinationAnalysisTableBody.vue';

@Component({
  components: {
    VaccinationAnalysisTableHeader,
    VaccinationAnalysisTableBody
  }
})
export default class VaccinationAnalysisTable extends Vue {
}
