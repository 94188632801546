


















































import { Component, Vue, Prop, Watch, Emit } from 'vue-property-decorator';
import { DropdownOption } from '@/models/poultry/dropdown.model';
import { reportTypeStores } from '@/store/poultry/reportType';
import { ReportTypeIds } from '@/constants';

@Component({})
export default class DropdownAlt extends Vue {
  @Prop() filterType!: string;

  @Prop({
    type: Array,
    default: [],
    required: true,
  })
  private options!: DropdownOption[];

  @Prop()
  reportTypeId!: string;

  @Prop()
  boldify!: boolean;

  @Prop()
  private selectedOption!: DropdownOption | string;
  isActive = false;
  selected!: DropdownOption;
  keywords: string = '';
  store = reportTypeStores.detail;
  
  @Watch('selectedOption', {deep: true})
  updateSelected(){
    if (this.selectedOption && this.selectedOption.constructor === Object) {
      this.selected = this.selectedOption as DropdownOption;
    } else {
      const option = this.options.find((el) => 
        el.value === this.selectedOption
      );
      if (option) {
        this.selected = option;
      } else {
        this.selected = this.options[0];
      }
    }
  }

  get isWaterReport(){
    return this.reportTypeId === ReportTypeIds.water;
  }

  get isInjectableReport(){
    return this.reportTypeId === ReportTypeIds.injectable;
  }

  get isHatcheryReport(){
    return this.reportTypeId === ReportTypeIds.hatchery;
  }

  get isCndReport(){
    return this.reportTypeId === ReportTypeIds.cnd;
  }

  get isIpmReport(){
    return this.reportTypeId === ReportTypeIds.ipm;
  }

  get isLowReport(){
    return this.reportTypeId === ReportTypeIds.labOnWheel;
  }

  get isEbaReport(){
    return this.reportTypeId === ReportTypeIds.eba;
  }

  get isPeaReport(){
    return this.reportTypeId === ReportTypeIds.pea;
  }

  @Watch('store.reportTypeId')
  resetSelection() {
    if (this.store.reportTypeId) {
      this.initializeDefaultOption();
    }
  }

  @Emit('select')
  handleSelected(data: DropdownOption) {
    this.selected = data;
    return data;
  }

  created() {
    this.initializeDefaultOption();
  }
  

  mounted() {
    document.addEventListener('click', this.closeDropdown);
  }

  destroyed() {
    document.removeEventListener('click', this.closeDropdown);
  }

  closeDropdown(e: any) {
    if (!this.$el.contains(e.target)) {
      this.isActive = false;
    }
  }

  @Emit('onSearch')
  handleFormSubmitted() {
    return this.keywords;
  }

  changeDropdownColor(){
    let dropdownColor = '';

    switch(this.reportTypeId){
      case ReportTypeIds.water:
        dropdownColor = 'water';
        break;
      case ReportTypeIds.injectable:
        dropdownColor = 'injectable';
        break;
      case ReportTypeIds.hatchery:
        dropdownColor = 'hatchery';
        break;
      case ReportTypeIds.cnd:
        dropdownColor = 'cleaning';
        break;
      case ReportTypeIds.ipm:
        dropdownColor = 'ipm';
        break;
      case ReportTypeIds.labOnWheel:
        dropdownColor = 'low';
        break;
      case ReportTypeIds.eba:
        dropdownColor = 'eba';
        break;
      case ReportTypeIds.pea:
        dropdownColor = 'pea';
        break;
      default:
        dropdownColor = '';
        break;
    }

    return `is-active-${dropdownColor}`;
  }

  initializeDefaultOption() {
    if (this.selectedOption && this.selectedOption.constructor === Object) {
      this.selected = this.selectedOption as DropdownOption;
    } else {
      const option = this.options.find((el) => 
        el.value === this.selectedOption
      );

      if (option) {
        this.selected = option;
      } else {
        this.selected = this.options[0];
      }
    }
  }
}
