import { FarmResponse } from '@/models/poultry/farm.model';
import { PaginationModel } from '@/models/poultry/pagination.model';
import { SearchPaginatedRequest } from '@/models/poultry/requests';
import { ErrorResponse } from '@/models/poultry/response';
import { axios } from '@/utils/axios';
import arraySort from 'array-sort';
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { DEFAULT_COUNT_PER_PAGE } from '@/constants';
import { clientStores } from '../client/index';
import { Endpoints } from '../endpoints';
import store from '../index';

export interface ListFarmStoreInterface {}
export interface FarmFetchAllParams extends Partial<PaginationModel> {
  state?: string,
  cities?: string[]
}
@Module({
  namespaced: true,
  dynamic: true,
  name: 'farm.list',
  store,
})
class ListFarmStore extends VuexModule {
  isLoading = false;
  error: ErrorResponse | null = null;
  farms: FarmResponse[] = [];
  pagination = { ...this.defaultPagination };
  apiParams: any = {
    state: null,
    cities: []
  };

  currentSort: keyof FarmResponse = 'createdDate';
  currentSortOrder = { reverse: true }; // false is asc, true is desc

  get defaultPagination(): PaginationModel {
    return {
      offset: 1,
      limit: DEFAULT_COUNT_PER_PAGE,
    };
  }

  get hasFarms(): boolean {
    return this.isLoading === false && Boolean(this.farms.length);
  }

  get sortedFarms() {
    const farmsCopy = [...this.farms];

    return arraySort(farmsCopy, this.currentSort, this.currentSortOrder);
  }

  @Mutation
  setSortType(params: any) {
    if (params === this.currentSort) {
      this.currentSortOrder.reverse = !this.currentSortOrder.reverse;
    }
    this.currentSort = params;
  }

  @Mutation
  updateFarms(params: FarmResponse[]) {
    this.farms = params;
  }

  @Mutation
  updatePagination(params: PaginationModel) {
    this.pagination = params;
  }

  @Mutation
  setError(params: ErrorResponse) {
    this.error = params;
  }

  @Mutation
  updateLoadingStatus(params: boolean) {
    this.isLoading = params;
  }

  @Mutation
  updateApiParams(apiParams: any) {
    this.apiParams = apiParams;
  }

  @Action
  async fetchAll(
    params: FarmFetchAllParams = { offset: 1, limit: DEFAULT_COUNT_PER_PAGE },
  ): Promise<void> {
    const { state, cities, offset, limit } = params;

    let _limit = Number(limit)

    this.context.commit('updateApiParams', {
      state: state || null,
      cities: cities || [] 
    })

    const apiParams: {[key: string]: number}= {};

    if (this.apiParams.state) {
      apiParams['state'] = this.apiParams.state.toString();
    }

    if (this.apiParams.cities.length) {
      apiParams['cities'] = this.apiParams.cities.toString();
    }
    
    apiParams['offset'] = offset ? ((offset - 1) * _limit) + 1 : 1;
    apiParams['limit'] = _limit ? _limit : 10;

    this.context.commit('updateLoadingStatus', true);
    try {
      const { data } = await axios.get(
        `${Endpoints.Clients}/${clientStores.details.clientId}/farms/search`,
        {
          params: apiParams,
        },
      );

      const { items, pagination } = data

      this.context.commit('updateFarms', items);
      this.context.commit('updatePagination', {
        ...pagination, 
        offset: ((pagination.offset - 1) / 10) + 1
      });
    } catch (error) {
      this.context.commit('setError', error);
    }
    this.context.commit('updateLoadingStatus', false);
  }

  @Action
  async searchByKeywords(params: SearchPaginatedRequest) {
    this.context.commit('updateLoadingStatus', true);

    if (!params.pagination) {
      params.pagination = {offset: 1, limit: DEFAULT_COUNT_PER_PAGE}
    }

    const { offset, limit } = params.pagination;

    try {
      const { data } = await axios.get(
        `${Endpoints.Clients}/${clientStores.details.clientId}/farms/search`,
        {
          params: {
            keywords: params.keywords,
            offset: ((offset - 1) * limit) + 1,
            limit,
          },
        },
      );

      const { items, pagination } = data

      this.context.commit('updateFarms', items);
      this.context.commit('updatePagination', {
        ...pagination, 
        offset: ((pagination.offset - 1) / 10) + 1
      });
    } catch (error) {
      this.context.commit('setError', error);
    }
    this.context.commit('updateLoadingStatus', false);
  }
}

export default getModule(ListFarmStore);
