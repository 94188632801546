import { ErrorResponse } from '@/models/poultry/response';
import { axios } from '@/utils/axios';
import { Action, getModule, Module, Mutation, MutationAction, VuexModule } from 'vuex-module-decorators';
import { DropdownOption } from '@/models/poultry/dropdown.model';
import { Endpoints } from '@/store/poultry/endpoints';
import store from '@/store/poultry/index';
import { AnalysisData, ScoreAnalysis } from '@/models/poultry/analysis.model';
import arraySort from 'array-sort';
import { ReportTypeIds, ReportComparisonType } from '@/constants';
import { AgeOfFlockResponse } from '@/models/poultry/ageOfFlock.model';
@Module({
  namespaced: true,
  dynamic: true,
  name: 'results.list',
  store
})
class ResultsAnalysisListStore extends VuexModule {
  isLoading = false;
  isCreated = false;
  results = [];
  chartStyle: string = 'horizontalBar';
  error: ErrorResponse | null = null;
  title: string | null = null;
  analysisType: string | null = null;
  reportTypeId: number | null = null;
  stateIds: string[] = [];
  farmIds: string[] = [];
  complexIds: string[] = [];
  auditorIds: string[] = [];
  clientIds: string[] = [];
  clientName: string | null = null;
  startDate: string | null = null;
  endDate: string | null = null;
  categoryType: string | null = null;
  auditType: string | null = 'all';
  sectionType: string | null = null;
  lesionType: string | null = null;
  peaLesionType: string | null = null;
  clientId: Number | null = null;
  daysOfFlockDropdown: string[] = [];
  weeksOfFlockDropdown: string[] = [];
  daysOfFlock: number | null = null;
  weeksOfFlock: number | null = null;
  trayNumbersDropdown: string[] = [];
  trayNumbers: string[] = [];
  currentSort: keyof AnalysisData = 'name';
  currentSortOrder = { reverse: true }; // false is asc, true is desc

  get hasResults(): boolean {
    return this.isLoading === false && Boolean(this.results.length);
  }

  get sortedResults() {
    const resultsCopy: AnalysisData[] = [...this.results];
    const finalResults: AnalysisData[] = [];

    resultsCopy.forEach(items => {
      let data = items.data.reduce((data: any, currentData: ScoreAnalysis) => {
        // if highest not empty or if current score greater, in highest
        if (!data.highestReport || currentData.score > data.highestReport.score) {
          data = {...data, highestReport : currentData};
        }
        // if have same highest score append month together
        else if (currentData.score === data.highestReport.score) {
          data = {
            ...data,
            highestReport: {
              ...data.highestReport,
              publishedMonth: data.highestReport.publishedMonth+ ', '+currentData.publishedMonth
            }
          }
        }

        // if lowest not empty or if current score lower, in lowest
        if (!data.lowestReport || currentData.score < data.lowestReport.score) {
          data = {...data, lowestReport : currentData};
        }
        // if have same lowest score append month together
        else if (currentData.score === data.lowestReport.score) {
          data = {
            ...data,
            lowestReport: {
              ...data.lowestReport,
              publishedMonth: data.lowestReport.publishedMonth+ ', '+currentData.publishedMonth
            }
          }
        }
        return data;
      }, {});
      // For Others row, that got score 0
      data = {
        highestReport : {
          score: 0,
          publishedMonth:'-'
        },
        lowestReport: {
          score: 0,
          publishedMonth:'-'
        },
        ...data,
      }
      // Append data object in items
      items = {
        ...items,
        ...data
      }
      // Push items to array
      finalResults.push(items);
    });
    return arraySort(finalResults, this.currentSort, this.currentSortOrder);
  }

  @Mutation
  setSortType(params: any) {
    if (params === this.currentSort) {
      this.currentSortOrder.reverse = !this.currentSortOrder.reverse;
    }
    this.currentSort = params;
  }

  @Mutation
  updateLoadingStatus(params: boolean) {
    this.isLoading = params;
  }

  @Mutation
  updateResults(params: any) {
    this.results = params;
  }

  @Mutation
  setAnalysisType(params: string) {
    this.analysisType = params;
  }

  @Mutation
  setStartDate(params: string) {
    this.startDate = params;
  }

  @Mutation
  setEndDate(params: string) {
    this.endDate = params;
  }

  @Mutation
  setClientIds(params: string[]) {
    this.clientIds = params;
  }

  @Mutation
  setClientName(params: DropdownOption) {
    this.clientName = params.text;
  }

  @Mutation
  setClientId(clientId: number) {
    this.clientId = clientId;
  }

  @Mutation
  updateDaysOfFlockDropdown(days: string[]){
    this.daysOfFlockDropdown = days;
  }

  @Mutation
  updateWeeksOfFlockDropdown(weeks: string[]){
    this.weeksOfFlockDropdown = weeks;
  }

  @Mutation
  updateTrayNumbersDropdown(trayNumbers: string[]){
    this.trayNumbersDropdown = trayNumbers;
  }

  @Mutation
  setDaysOfFlock(day: number){
    this.daysOfFlock = day;
  }

  @Mutation
  setWeeksOfFlock(week: number){
    this.weeksOfFlock = week;
  }

  @Mutation
  setTrayNumbers(params: string[]){
    this.trayNumbers = params;
  }

  @Mutation
  setCategoryType(params: string) {
    this.categoryType = params;
  }

  @Mutation
  setAuditType(params: string) {
    this.auditType = params;
		this.sectionType = 'all'
  }

  @Mutation
  setSectionType(params: string) {
    this.sectionType = params;
  }

  @Mutation
  setLesionType(params: string) {
    this.lesionType = params;
  }

  @Mutation
  setPeaLesionType(params: string) {
    this.peaLesionType = params;
  }

  @Mutation
  setStates(params: string[]) {
    this.stateIds = params;
  }

  @Mutation
  setFarms(params: string[]) {
    this.farmIds = params;
  }

  @Mutation
  setComplexes(params: string[]) {
    this.complexIds = params;
  }

  @Mutation
  setAuditors(params: string[]) {
    this.auditorIds = params;
  }

  @Mutation
  setError(params: ErrorResponse) {
    this.error = params;
  }

  @Mutation
  setResultsCreated(params: boolean) {
    this.isCreated = params;
  }

  @Mutation
  setReportTypeId(params: any) {
    this.reportTypeId = Number(params)
    if (Number(params) !== 5) {
      this.auditType = null;
      this.sectionType = null;
    }
  }

  @Mutation
  setChartStyle(params: string) {
    this.chartStyle = params;
  }

  // TODO: Refactor
  @Action
  async fetchAll() {
    this.context.commit('updateLoadingStatus', true);
    this.context.commit('setResultsCreated', false);
    try {
      if (this.analysisType === 'between_clients') {
        const { data } = await axios.get(Endpoints.ResultsAnalysis, {
          params: {
            analysis_type: this.analysisType,
            client_ids: this.clientIds.toString(),
            audit_type: this.auditType,
            section_type: this.sectionType,
            report_type_id: this.reportTypeId,
            start_date: this.startDate,
            end_date: this.endDate,
            reportTypeId: this.reportTypeId,
            pea_lesion_type: this.peaLesionType
          }
        });
        this.context.commit('updateResults', data);
        this.context.commit('setResultsCreated', true);
      }

      if (this.analysisType === 'between_farms' && this.reportTypeId !== Number(ReportTypeIds.eba)) {
        if (this.categoryType === 'state') {
          const { data } = await axios.get(Endpoints.ResultsAnalysis, {
            params: {
              analysis_type: this.analysisType,
              client_id: this.clientIds.toString(),
              audit_type: this.auditType,
              section_type: this.sectionType,
              report_type_id: this.reportTypeId,
              start_date: this.startDate,
              end_date: this.endDate,
              state_ids: this.stateIds.toString(),
              pea_lesion_type: this.peaLesionType
            }
          });
          this.context.commit('updateResults', data);
          this.context.commit('setResultsCreated', true);
        }
  
        if (this.categoryType === 'farm') {
          const { data } = await axios.get(Endpoints.ResultsAnalysis, {
            params: {
              analysis_type: this.analysisType,
              client_id: this.clientIds.toString(),
              audit_type: this.auditType,
              section_type: this.sectionType,
              report_type_id: this.reportTypeId,
              start_date: this.startDate,
              end_date: this.endDate,
              farm_ids: this.farmIds.toString(),
              pea_lesion_type: this.peaLesionType
            }
          });
          this.context.commit('updateResults', data);
          this.context.commit('setResultsCreated', true); 
        }
  
        if (this.categoryType === 'auditor') {
          const { data } = await axios.get(Endpoints.ResultsAnalysis, {
            params: {
              analysis_type: this.analysisType,
              client_id: this.clientIds.toString(),
              audit_type: this.auditType,
              section_type: this.sectionType,
              report_type_id: this.reportTypeId,
              start_date: this.startDate,
              end_date: this.endDate,
              auditor_ids: this.auditorIds.toString(),
              pea_lesion_type: this.peaLesionType
            }
          });
          this.context.commit('updateResults', data);
          this.context.commit('setResultsCreated', true); 
        }
      }

      if (this.analysisType === 'between_complexes') {
        if (this.categoryType === 'state') {
          const { data } = await axios.get(Endpoints.ResultsAnalysis, {
            params: {
              analysis_type: this.analysisType,
              client_id: this.clientIds.toString(),
              audit_type: this.auditType,
              section_type: this.sectionType,
              report_type_id: this.reportTypeId,
              start_date: this.startDate,
              end_date: this.endDate,
              state_ids: this.stateIds.toString(),
            }
          });
          this.context.commit('updateResults', data);
          this.context.commit('setResultsCreated', true);
        }
  
        if (this.categoryType === 'auditor') {
          const { data } = await axios.get(Endpoints.ResultsAnalysis, {
            params: {
              analysis_type: this.analysisType,
              client_id: this.clientIds.toString(),
              audit_type: this.auditType,
              section_type: this.sectionType,
              report_type_id: this.reportTypeId,
              start_date: this.startDate,
              end_date: this.endDate,
              auditor_ids: this.auditorIds.toString(),
            }
          });
          this.context.commit('updateResults', data);
          this.context.commit('setResultsCreated', true); 
        }
      }

      if (this.analysisType === ReportComparisonType.betweenHatchers) {
        const { data } = await axios.get(Endpoints.ResultsAnalysis, {
          params: {
            analysis_type: this.analysisType,
            client_id: this.clientId,
            report_type_id: this.reportTypeId,
            start_date: this.startDate,
            end_date: this.endDate,
            farm_ids: this.farmIds.toString(),
            eba_lesion_type: this.lesionType,
            days_of_flock: this.daysOfFlock,
            weeks_of_flock: this.weeksOfFlock
          }
        });
        this.context.commit('updateResults', data);
        this.context.commit('setResultsCreated', true);
      }

      if (this.analysisType === ReportComparisonType.betweenTrays) {
        const { data } = await axios.get(Endpoints.ResultsAnalysis, {
          params: {
            analysis_type: this.analysisType,
            client_id: this.clientId,
            report_type_id: this.reportTypeId,
            start_date: this.startDate,
            end_date: this.endDate,
            farm_ids: this.farmIds.toString(),
            eba_lesion_type: this.lesionType,
            tray_numbers: this.trayNumbers.toString()
          }
        });
        this.context.commit('updateResults', data);
        this.context.commit('setResultsCreated', true);
      }

      if (this.analysisType === ReportComparisonType.betweenPeaHatchers) {
        const { data } = await axios.get(Endpoints.ResultsAnalysis, {
          params: {
            analysis_type: this.analysisType,
            client_id: this.clientId,
            report_type_id: this.reportTypeId,
            start_date: this.startDate,
            end_date: this.endDate,
            farm_ids: this.farmIds.toString(),
            pea_lesion_type: this.peaLesionType,
            days_of_flock: this.daysOfFlock,
            weeks_of_flock: this.weeksOfFlock
          }
        });
        this.context.commit('updateResults', data);
        this.context.commit('setResultsCreated', true);
      }

    } catch(error) {
      this.context.commit('setError', error);
    }
    this.context.commit('updateLoadingStatus', false);
  }

  @Action
  async fetchAgeOfFlock() {
    this.context.commit('updateLoadingStatus', true);
    try {
      
      const { data } = await axios.get(`${Endpoints.ResultsAnalysis}/ageOfFlock/dropdown`, {
        params: {
          analysis_type: this.analysisType,
          client_id: this.clientId,
          farm_ids: this.farmIds,
          report_type_id: this.reportTypeId
        }
      });

      this.context.commit('updateDaysOfFlockDropdown', data.days);
      this.context.commit('updateWeeksOfFlockDropdown', data.weeks);

    } catch(error) {
      this.context.commit('setError', error);
    }
    this.context.commit('updateLoadingStatus', false);
  }

  @Action
  async fetchTrayNumbers() {
    this.context.commit('updateLoadingStatus', true);
    try {
      
      const { data } = await axios.get(`${Endpoints.ResultsAnalysis}/trayNumbers/dropdown`, {
        params: {
          analysis_type: this.analysisType,
          client_id: this.clientId,
          farm_ids: this.farmIds,
          report_type_id: this.reportTypeId
        }
      });
      this.context.commit('updateTrayNumbersDropdown', data.trayNumber);

    } catch(error) {
      this.context.commit('setError', error);
    }
    this.context.commit('updateLoadingStatus', false);
  }

  @Action
  async searchTrayNumbers(keyword: string) {
    this.context.commit('updateLoadingStatus', true);
    try {
      
      const { data } = await axios.get(`${Endpoints.ResultsAnalysis}/trayNumbers/search`, {
        params: {
          client_id: this.clientId,
          farm_ids: this.farmIds,
          report_type_id: this.reportTypeId,
          keywords: keyword
        }
      });
      this.context.commit('updateTrayNumbersDropdown', data.trayNumber);

    } catch(error) {
      this.context.commit('setError', error);
    }
    this.context.commit('updateLoadingStatus', false);
  }

}

export default getModule(ResultsAnalysisListStore);
