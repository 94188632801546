export const phase2_scheme = [
  '#B28D16',
  '#506128',
  '#DB5D98',
  '#2E75B5',
  '#73C0FF',
  '#4BB63A',
  '#C2A8CB',
  '#AC0303',
  '#B24E00',
  '#5D4037',
  '#9227A3',
  '#A5A2A2',
  '#13006A',
  '#395474',
  '#189065',
  '#FFB479',
  '#D00EC8',
  '#1F1F1F',
  '#767070',
  '#269FB4'
]

export const phase3_scheme = [
  '#00BDC4',
  '#5144D3',
  '#E8871A',
  '#DA348F',
  '#9188FA',
  '#42D468',
  '#277FEA',
  '#7039B1',
  '#DFBF03',
  '#CB6F0F',
  '#1C5F1E',
  '#268D6C',
  '#9AEC54',
  '#00C792',
  '#3A44CF',
  '#FF6830',
  '#E84669',
  '#BCA799',
  '#2B7294',
  '#83ACF3',
  '#963CBA',
  '#CB87C2',
  '#84541A'
]

export const phase4_scheme = [
  '#FF9D00',
  '#83E03D',
  '#0072CE',
  '#DA348F',
  '#9188FA',
  '#42D468',
  '#277FEA',
  '#7039B1',
  '#DFBF03',
  '#CB6F0F',
  '#1C5F1E',
  '#268D6C',
  '#9AEC54',
  '#00C792',
  '#3A44CF',
  '#FF6830',
  '#E84669',
  '#BCA799',
  '#2B7294',
  '#83ACF3',
  '#963CBA',
  '#CB87C2',
  '#84541A'
]