import { DEFAULT_COUNT_PER_PAGE } from '@/constants';
import { ErrorResponse } from '@/models/poultry/response';
import { axios } from '@/utils/axios';
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule
} from 'vuex-module-decorators';
import { Endpoints } from '../endpoints';
import store from '../index';
import { Report, ReportResponse, ReportDetailsResponse } from '@/models/poultry/report.model';
import { reportStores } from '@/store/poultry/report/';

export interface ReportDetailsStoreInterface {

}

@Module({
  namespaced: true,
  dynamic: true,
  name: 'report.details',
  store
})

class ReportDetailStore extends VuexModule {
  isLoading = false;
  isDeleted = false;
  error: ErrorResponse | null = null;
  report = {} as ReportDetailsResponse;
  reportId: number | null = null;
  reportIds: number[] = [];

  get hasReportIds(): boolean {
    return this.isLoading === false && Boolean(this.reportIds.length);
  }

  @Mutation
  updateReport(params: ReportDetailsResponse) {
    this.report = params;
  }

  @Mutation
  resetReportIds() {
    this.reportIds = [];
  }

  @Mutation
  addAllReportIds() {
    this.reportIds = reportStores.list.reports.map(report => report.id);
  }

  @Mutation
  addReportId(params: number) {
    if (this.reportIds.indexOf(params) === -1) {
      this.reportIds.push(params);
    }
  }

  @Mutation
  removeReportId(params: number) {
    this.reportIds = this.reportIds.filter(id => {
      return id != params;
    })
  }

  @Mutation
  setReportId(params: number) {
    this.reportId = params;
  }

  @Mutation
  setError(params: ErrorResponse) {
    this.error = params;
  }

  @Mutation
  updateLoadingStatus(params: boolean) {
    this.isLoading = params;
  }

  @Mutation
  setDeleteFlag(params: boolean) {
    this.isDeleted = params;
  }

  @Action
  async fetchById() {
    this.context.commit('updateLoadingStatus', true);
    try {
      await axios.get(`${Endpoints.Reports}/poultry/${this.reportId}`).then((response) => {
        this.context.commit('updateReport', response.data);
      });
    } catch (error) {
      this.context.commit('setError', error.data.reportType);
    }
    this.context.commit('updateLoadingStatus', false);
  }

  @Action
  async delete() {
    this.context.commit('updateLoadingStatus', true);
    try {
      await axios.delete(`${Endpoints.Reports}/poultry`, {
        params: {
          reportIds: this.reportIds.toString()
        }
      });
      this.context.commit('setDeleteFlag', true);
    } catch (error) {
      this.context.commit('setError', error);
    }
    this.context.commit('updateLoadingStatus', false);
  }
}

export default getModule(ReportDetailStore);
