import { ErrorResponse } from '@/models/poultry/response';
import { axios } from '@/utils/axios';
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { Endpoints } from '../endpoints';
import store from '../index';
import { clientStores } from '@/store/poultry/client/index';
import { FarmLocationDetails, FarmResponse } from '@/models/poultry/farm.model';

export interface FarmDetailsStoreInterface {
}

export interface FetchFarmsDropdownParams {
  clientId: string;
  cities: [],
  state: string;
}

@Module({
  namespaced: true,
  dynamic: true,
  name: 'farm.details',
  store
})

class FarmDetailsStore extends VuexModule {
  isLoading = false;
  isUpdated = false;
  isDeleted = false;
  error: ErrorResponse | null = null;
  farm = {} as FarmResponse;
  farmsDropdown: FarmResponse[] = [];
  farms: FarmLocationDetails | null = null;
  farmId: number | null = null;

  apiParams: any = {
    cities: [],
    state: null
  }

  @Mutation
  updateFarm(params: any) {
    this.farms = params;
  }

  @Mutation
  updateApiParams(params: any) {
    this.apiParams = params;
  }

  @Mutation
  updateFarmsDropdown(farmsDropdown: FarmResponse[]) {
    this.farmsDropdown = farmsDropdown;
  }

  @Mutation
  setFarmId(params: number) {
    this.farmId = params;
  }

  @Mutation
  setError(params: ErrorResponse) {
    this.error = params;
  }

  @Mutation
  updateLoadingStatus(params: boolean) {
    this.isLoading = params;
  }

  @Mutation
  setUpdateFlag(params: boolean) {
    this.isUpdated = params;
  }

  @Mutation
  setDeleteFlag(params: boolean) {
    this.isDeleted = params;
  }

  @Action
  async fetchById() {
    this.context.commit('updateLoadingStatus', true);
    try {
      const { data } = await axios.get(`${Endpoints.Clients}/${clientStores.details.clientId}/farms/${this.farmId}`);

      this.context.commit('updateFarm', data);
    } catch(error) {
      this.context.commit('setError', error);
    }
    this.context.commit('updateLoadingStatus', false);
  }

  @Action
  async fetchFarmsDropdown(params: FetchFarmsDropdownParams) {
    const { clientId, cities, state } = params;
    const apiParams: any = {};

    this.context.commit('updateApiParams', {
      cities: cities || [],
      state: state || null,
    })

    if (cities.length) {
      apiParams['cities'] = cities.map((c: any) => c.trim()).join(',')
    }

    if (state) {
      apiParams['state'] = state;
    }

    this.context.commit('updateLoadingStatus', true);
    try {
      const { data } = await axios.get(`${Endpoints.Clients}/${clientId}/farms/dropdown`);
      this.context.commit('updateFarmsDropdown', data);
    } catch (error) {
      this.context.commit('setError', error);
    }
    this.context.commit('updateLoadingStatus', false);
  }

  @Action
  async update() {
    this.context.commit('updateLoadingStatus', true);
    try {
      const { data } = await axios.put(`${Endpoints.Clients}/${clientStores.details.clientId}/farms/${this.farmId}`, this.farms);
      this.context.commit('setUpdateFlag', true);
      this.context.commit('updateFarm', data);
    } catch(error) {
      this.context.commit('setError', error);
    }
    this.context.commit('updateLoadingStatus', false);
  }

  @Action
  async delete() {
    this.context.commit('setDeleteFlag', false);
    this.context.commit('updateLoadingStatus', true);
    try {
      await axios.delete(`${Endpoints.Clients}/${clientStores.details.clientId}/farms/${this.farmId}`);
      this.context.commit('setDeleteFlag', true);
    } catch(error) {
      this.context.commit('setError', error);
    }
    this.context.commit('updateLoadingStatus', false);
  }
}

export default getModule(FarmDetailsStore);
