















import { Component, Ref, Vue } from 'vue-property-decorator';
import DropdownFilterAltResults from '@/components/poultry/DropdownFilterAltResults.vue';
import { reportStores } from '@/store/poultry/report';
import { clientStores } from '@/store/poultry/client';
import { DropdownOption } from '@/models/poultry/dropdown.model';
import { farmStores } from '@/store/poultry/farm/index';
import { auditorStores } from '@/store/poultry/auditor';

@Component({
  components: {
    DropdownFilterAltResults,
  },
})
export default class ClientNameFilter extends Vue {
  farmStore = farmStores.details;
  reportListStore = reportStores.list;
  clientListStore = clientStores.list;
  clientDetailStore = clientStores.details;
  auditorStore = auditorStores.details;
  clientOptions: any = [];
  defaultClient: any = {};
  @Ref() activeSelectedDropdown!: any;

  async created() {
     const species = (this.$router.currentRoute.fullPath.split('/'))[1];

    this.clientListStore.setSpecies(species)
    await this.clientListStore.fetchClients();
    this.clientOptions = [...this.clients];
  }

  get selectedClientId() {
    return this.reportListStore.apiParams.clientId;
  }

  // Array of objects of Client Name
  get clients() {
    const clientsDropdown = this.clientListStore.clientsDropdown.map((data) => {
      return {
        text: data.name,
        value: data.id,
      };
    });

    return [
      ...clientsDropdown,
    ];
  }

  handleSelectedClientId(params: DropdownOption) {
    this.reportListStore.updatePagination(this.reportListStore.defaultPagination);

    this.reportListStore.fetchAll({
      clientId: params.value,
      farmIds: [],
      reportTypeIds: [],
      auditorIds: [],
      months: [],
      years: [],
    });

    if (params.value) {
      this.defaultClient = params.value;
      // Enable the Farm name and Auditor name dropdown
      this.clientListStore.updateAllClientsState(false);
      this.auditorStore.fetchAuditorsDropdown(params.value);
      this.farmStore.fetchFarmsDropdown({
        ...this.farmStore.apiParams,
        clientId: params.value,
      });
    } else {
      // Disable the Farm name and Auditor name dropdown
      this.clientListStore.updateAllClientsState(true);
    }
  }
}
