





import { Component, Vue } from 'vue-property-decorator';
import Chart from 'chart.js';
import { analysisStores } from '@/store/poultry/analysis/index';
import dayjs, { Dayjs } from 'dayjs';
import { AnalysisData, BarChartProperties } from '@/models/poultry/analysis.model';
import { phase2_scheme } from '@/utils/chart.color';
import { phase2_chart } from '@/utils/chart.config';

@Component({
  components: {},
})
export default class VaccinationAnalysisChartBody extends Vue {
  store = analysisStores.vaccination.list;
  analysisChart: Chart = new Chart('', {});
  chartKey = Math.random();
  chartColor = phase2_scheme;

  get vaccinations(): AnalysisData[] {
    return this.store.vaccination;
  }

  get monthVaccinations() {
    const startDate = this.store.startDate;
    const endDate = this.store.endDate;

    let dates: Dayjs[] = [];
    let dateObj = dayjs(startDate!);
    const endDateObj = dayjs(endDate!).startOf('month').endOf('day');

    while (dateObj.isBefore(endDateObj)) {
      dates.push(dateObj);
      dateObj = dateObj.add(1, 'month');
    }
    return dates;
  }

  get months(): string[] {
    return this.monthVaccinations.map((dateObj) => dateObj.format('MMM YY'));
  }

  scores(vaccination: AnalysisData) {
    let scoresArr: number[] = [];
    let data = vaccination.data;

    data.forEach(data => {
      scoresArr.push(data.score);
    });

    return scoresArr;
  }

  get dataSets() {
    const resultArr: BarChartProperties[] = [];
    this.vaccinations.forEach((vaccination, i) => {
      resultArr.push({
        label: vaccination.name,
        data: this.scores(vaccination),
        backgroundColor: this.chartColor[i % this.chartColor.length],
      });
    })
    return resultArr;
  }

  get chartData() {
    return {
      ...phase2_chart,
      data: {
        labels: this.months,
        datasets: this.dataSets,
      },
    };
  }

  createChart(chartId: any, chartData: any) {
    const ctx = document.getElementById(chartId) as HTMLCanvasElement;
    this.analysisChart = new Chart(ctx, {
      type: chartData.type,
      data: chartData.data,
      options: chartData.options,
    });
  }

  mounted() {
    this.createChart('analysisChart', this.chartData);
  }
}
