












import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import EmptyAnalysisPlaceholder from '../EmptyAnalysisPlaceholder.vue';
import VaccinationAnalysisPageHeader from './VaccinationAnalysisPageHeader.vue';
import { analysisStores } from '@/store/poultry/analysis/index';
import VaccinationAnalysisChart from './VaccinationAnalysisChart/VaccinationAnalysisChart.vue';
import VaccinationAnalysisTable from './VaccinationAnalysisTable/VaccinationAnalysisTable.vue';

@Component({
  components: {
    VaccinationAnalysisPageHeader,
    VaccinationAnalysisChart,
    VaccinationAnalysisTable,
    EmptyAnalysisPlaceholder
  }
})
export default class VaccinationAnalysis extends Vue {
  store = analysisStores.vaccination.list;

  created() {
    this.store.setVaccinationCreated(false);
  }

  get isVaccinationCreated() {
    return this.store.isCreated;
  }
}
