import { ClientResponse } from '@/models/poultry/client.model';
import { City, Country, Region } from '@/models/poultry/country.model';
import { ErrorResponse } from '@/models/poultry/response';
import { axios } from '@/utils/axios';
import {
  Action,
  getModule,
  Module,
  Mutation,
  MutationAction,
  VuexModule,
} from 'vuex-module-decorators';
import { Endpoints } from '../endpoints';
import store from '../index';

export interface CountryListStoreInterface {
  response: ClientResponse | null;
  isLoading: boolean;
  error: ErrorResponse | null;
}
@Module({
  namespaced: true,
  dynamic: true,
  name: 'country.list',
  store,
})
class CountryListStore extends VuexModule {
  isLoading = false;
  isAllStateSelected = false;
  error: ErrorResponse | null = null;
  selectedCountryCode: string | null = null;
  selectedRegion: string | null = null;
  countries: Country[] = [];
  regions: Region[] = [];
  cities: City[] = [];

  @Mutation
  setError(params: ErrorResponse) {
    this.error = params;
  }

  @Mutation
  setCountries(params: Country[]) {
    this.countries = params;
  }

  @Mutation
  setRegions(params: Region[]) {
    this.regions = params;
  }

  @Mutation
  setCities(params: City[]) {
    this.cities = params;
  }

  @Mutation
  setCountryCode(params: string) {
    this.selectedCountryCode = params;
  }

  @Mutation
  setRegion(params: string) {
    this.selectedRegion = params;
  }

  @Action
  updateCountryCode(countryCode: string) {
    this.context.commit('setCountryCode', countryCode);
  }

  @Action
  updateState(state: string) {
    this.context.commit('setRegion', state);
  }

  @Mutation
  updateLoadingStatus(params: boolean) {
    this.isLoading = params;
  }

  @Mutation
  updateIsAllStateSelected(params: boolean) {
    this.isAllStateSelected = params;
  }

  @Action
  async fetchCountries() {
    this.context.commit('updateLoadingStatus', true);
    try {
      const { data } = await axios.get<Country[]>(Endpoints.Countries);
      this.context.commit('setCountries', data);
    } catch (error) {
      this.context.commit('setError', error);
    }

    this.context.commit('updateLoadingStatus', false);
  }

  @Action
  async fetchStatesByCountryCode() {
    try {
      const params = {} as { [key: string]: string };

      if (this.selectedCountryCode) {
        params['country'] = this.selectedCountryCode;
      }

      const { data } = await axios.get<Region[]>(Endpoints.States, {
        params,
      });

      if (data.length) {
        this.updateState(data[0].name);
      }

      this.context.commit('setRegions', data);
    } catch (error) {
      this.context.commit('setError', error);
    }
  }

  @Action
  async fetchCityByStateCode(regionCode?: string) {
    if (!regionCode) {
      this.context.commit('setCities', []);
      return;
    }

    try {
      const params = {} as { [key: string]: string };

      if (regionCode) {
        params['state'] = regionCode;
      }

      const { data } = await axios.get(Endpoints.Cities, {
        params,
      });

      this.context.commit('setCities', data);
    } catch (error) {
      this.context.commit('setError', error);
    }
  }
}

export default getModule(CountryListStore);
